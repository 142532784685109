import React, {useEffect, useState} from "react";
import _ from "lodash";

//モーダルインポート
import RoomDetailModal from "../modal/RoomDetailModal";

import StaySearchResult from "./StaySearchResult";
import HotelPlanSearchResult from "./HotelPlanSearchResult"
import PartsHotelNarrowDown from "../parts/PartsHotelNarrowDown";
import PartsHotelPlanNarrowDown from "../parts/PartsHotelPlanNarrowDown";

import {HotelAllSearchResponse, selectHotelDetail} from "../../types/Hotel";
import {PlanSelectFormData} from "../../types/planSelectForm";
import {filterHotels, hotelAreaCodeLabel, hotelTypeCodeLabel, scrollToHotelSection} from "../../utils/hotel";
import {hotelTypeCode, okinawaAreaCodeLabel} from "../../config/hotel";
import {getHotelSort} from "../../utils/hotel/getHotelSort";

type Props = {
    planSelectFormData: PlanSelectFormData,
    selectPlanSelectFormData(planSelectFromData:PlanSelectFormData):void,
    isLoading: boolean,
    isListLoading: boolean,
    callback: () => void,
    setHotelSelectedStep(step: number): void,
    sort: (type: string, val: number) => void;
    hotelAllData: HotelAllSearchResponse | undefined;
    hotelController: AbortController;
    handleHotelSelected: (selectedHotel: selectHotelDetail) => void;
}

const StaySearchbox: React.VFC<Props> = ({
    planSelectFormData,
    selectPlanSelectFormData,
    isLoading,
    isListLoading,
    callback,
    setHotelSelectedStep,
    sort,
    hotelAllData,
    hotelController,
    handleHotelSelected
}) => {
    const [hotelData, setHotelData] = useState<HotelAllSearchResponse | undefined>();
    const [priceLoading, setPriceLoading] = React.useState(true); // 料金取得の処理中か
    const hotelRoomAllAbortController = new AbortController();
    const hotelPlanAbortController = new AbortController();

    //エリアセレクトボックス
    const [hotelAreas, setHotelAreas] = useState<string[]>([]);
    const [hotelTypes, setHotelTypes] = useState<string[]>([]);

    const getHotelAreas = (target: string | undefined) => {
        if (target === 'エリア指定') {
            return planSelectFormData.sellerProduct ? planSelectFormData.sellerProduct.hotelSettings.hotelCode : planSelectFormData.planData?.sellerIndo.hotelSettings.hotelCode;
        }
        return hotelAllData?.subAreaCodes;
    }
    const target = planSelectFormData.sellerProduct ? planSelectFormData.sellerProduct.hotelSettings.target : planSelectFormData.planData?.sellerIndo.hotelSettings.target;
    const areas = getHotelAreas(target);
    if (areas) {
        const areaCodes = areas.split(",");
        if (areaCodes.length > 1) {
            areaCodes.forEach((areaCode, index) => {
                if (!hotelAreas.includes(areaCode)) {
                    setHotelAreas([...hotelAreas, areaCode])
                }
            })
        }
    }
    if (hotelAllData?.hotelTypeCodes) {
        const typeCodes = hotelAllData.hotelTypeCodes.split(",");
        if (typeCodes.length > 1) {
            typeCodes.forEach((typeCode, index) => {
                if (!hotelTypes.includes(typeCode)) {
                    setHotelTypes([...hotelTypes, typeCode])
                }
            })
        }
    }

    const [hotelTypesFilterState, setHotelTypesFilterState] = React.useState<string[]>([]);
    const handleHotelTypesFilter = (
      event: React.MouseEvent<HTMLElement>,
      newHotelTypesToggle: string[],
    ) => {
        setHotelTypesFilterState(newHotelTypesToggle);
    };

    const [hotelAreasFilterState, setHotelAreasFilterState] = React.useState<string[]>([]);
    const handleHotelAreasFilter = (
      event: React.MouseEvent<HTMLElement>,
      newHotelAreasToggle: string[],
    ) => {
        setHotelAreasFilterState(newHotelAreasToggle);
    };

    // todo ホテルAPI修正できれば不要になる
    const setHotelAreasByHotelAllData = () => {
        if (hotelAllData) {
            const _hotelAreas = _.cloneDeep(hotelAreas);
            hotelAllData.hotelList.forEach((hotelData) => {
                if (!_hotelAreas.includes(hotelData.sub_area_code)) {
                    _hotelAreas.push(hotelData.sub_area_code)
                }
            })
            const areaSortArray = Object.keys(okinawaAreaCodeLabel);
            setHotelAreas(_hotelAreas.sort((a, b) => {
                return areaSortArray.indexOf(a) - areaSortArray.indexOf(b);
            }));
        }
    }

    // todo ホテルAPI修正できれば不要になる
    const setHotelTypesByHotelAllData = () => {
        const _hotelTypes = _.cloneDeep(hotelTypes);
        if (hotelAllData) {
            hotelAllData.hotelList.forEach((hotelData) => {
                if (hotelData.hotel_type_list && hotelData.hotel_type_list.length > 0) {
                    hotelData.hotel_type_list.forEach((hotelTypeData) => {
                        if (!_hotelTypes.includes(hotelTypeData.hotel_type_code)) {
                            _hotelTypes.push(hotelTypeData.hotel_type_code)
                        }
                    });
                }
            })
            setHotelTypes(_hotelTypes.sort());
        }
    }

    useEffect(() => {
        setHotelAreasByHotelAllData();
        setHotelTypesByHotelAllData();
        setHotelData(filterHotels(hotelAllData, hotelAreasFilterState, hotelTypesFilterState));
    }, [hotelAreasFilterState, hotelTypesFilterState, hotelAllData]);

    useEffect(() => {
        if (hotelData && hotelData?.hotelList.length > 0) {
            handleHotelSelected(hotelData.hotelList[0]);
        }
    }, [hotelData])

    /**
     * 部屋タイプのフィルター
     */
    const [roomTypeFilterState, setRoomTypeFilterState] = React.useState<string[]>([]);
    const handleRoomTypeFilter = (
        event: React.MouseEvent<HTMLElement>,
        newRoomDetailToggle: string[],
    ) => {
        setRoomTypeFilterState(newRoomDetailToggle);
    };

    const [mealFilterState, setMealFilterState] = React.useState<string[]>([]);
    const handleMealFilter = (
        event: React.MouseEvent<HTMLElement>,
        newRoomDetailToggle: string[],
    ) => {
        setMealFilterState(newRoomDetailToggle);
    };


    const [selectedHotelSort, setSelectedHotelSort] = React.useState<number>(getHotelSort(planSelectFormData.sellerProduct?.hotelSettings?.sort ?? '')); // 0: おすすめ順, 1: 価格が安い順, 2: 価格が高い順
    const [selectedHotelSortKey, setSelectedHotelSortKey] = React.useState<string>("min_price");
    const [selectedRoomSort, setSelectedRoomSort] = React.useState("asc"); // asc: 安い順、desc: 高い順


    // 絞り込み条件(ルームタイプ)
    const [roomTypeFilter, setRoomTypeFilter] = React.useState<{label: string, value: string}[]>([]);
    const [mealFilter, setMealFilter] = React.useState<{label: string, value: string}[]>([]);

    // モーダル：客室詳細
    const [roomDetailModalState, setRoomDetailModalState] = React.useState(false);
    const handleRoomDetailModal = () => {
        setRoomDetailModalState(!roomDetailModalState);
    };

    //カウンター
    const[ StaySearchPageView, setStaySearchPageView ] = React.useState(1);

    const showHotelView = () => {
        setStaySearchPageView(StaySearchPageView-1)
        scrollToHotelSection(); // `宿泊の選択`の場所にスクロールする
    }

    const [currentHotelCode, setCurrentHotelCode] = React.useState("all");
    const [selectedHotel, setSelectedHotel] = React.useState<selectHotelDetail>(); // 選択したホテルのデータ

    const selectHotel = (hCode: string) => {
        setCurrentHotelCode(hCode);
        const selectedHotel = _.find(hotelAllData?.hotelList, (hotelData) => hotelData.hotel_code === hCode);
        setSelectedHotel(selectedHotel);
    }

    /**
     * 宿泊施設の並び替え
     * @param sortKey
     * @param sortType
     */
    const sortHotels = (sortKey: string, sortType: number) => {
        if (isLoading) {
            return
        }
        setHotelData(undefined);
        sort(sortKey, sortType);
        setSelectedHotelSortKey(sortKey);
        setSelectedHotelSort(sortType);
    }

    /**
     * 宿泊プランの並び替え
     * @param sortType
     */
    const sortRoomPlan = (sortType: string) => {
        setSelectedRoomSort(sortType);
    }

    /**
     * ホテル一覧とプラン選択の画面遷移時
     */
    useEffect(() => {
        setSelectedRoomSort("asc"); // 並び替えを解除する
        setRoomTypeFilter([]);
        setMealFilter([]);
        setHotelSelectedStep(StaySearchPageView);
        if (StaySearchPageView === 1) {
            hotelRoomAllAbortController.abort();
            hotelPlanAbortController.abort();
            sort(selectedHotelSortKey, selectedHotelSort);
        } else {
            hotelController.abort();
        }
    }, [StaySearchPageView, setHotelSelectedStep]);

    useEffect(() => {
        if (hotelAllData?.search_sort && target === 'エリア指定') {
            setSelectedHotelSort(hotelAllData.search_sort);
            setSelectedHotelSortKey("min_price");
        }
    }, [hotelAllData]);

    return (
        <>
            {hotelAllData && (
                <div className="mt-20">
                    {/* 絞り込み条件 */}
                    {hotelAreas.length > 1 && StaySearchPageView === 1 && !isLoading ? (
                        <>
                            {/* 宿泊施設の絞り込み条件 */}
                            <PartsHotelNarrowDown
                                hotelAreas = {hotelAreas}
                                hotelTypes= {hotelTypes}
                                hotelAreasFilterState= {hotelAreasFilterState}
                                hotelTypesFilterState= {hotelTypesFilterState}
                                handleHotelAreasFilter= {handleHotelAreasFilter}
                                handleHotelTypesFilter= {handleHotelTypesFilter}
                                hotelAreaCodeLabel= {hotelAreaCodeLabel}
                                hotelTypeCodeLabel= {hotelTypeCodeLabel}
                            />
                        </>
                    ) : StaySearchPageView === 2 && !priceLoading && (
                        <>
                            {/* 宿泊プランの絞り込み条件 */}
                            <PartsHotelPlanNarrowDown
                                roomTypeFilter= {roomTypeFilter}
                                mealFilter= {mealFilter}
                                roomTypeFilterState= {roomTypeFilterState}
                                mealFilterState= {mealFilterState}
                                handleRoomTypeFilter= {handleRoomTypeFilter}
                                handleMealFilter= {handleMealFilter}
                            />
                        </>
                    )}

                    {/* 検索結果ヘッダー */}
                    {StaySearchPageView === 1 ? (
                        // 宿泊施設の検索結果ヘッダー
                        <div className="animation-fade active">
                            <div className="d-f jc-sb mt-24 mt-40-md mb-16">
                                <div className="fz-12 fz-14-md fw-b">
                                    検索結果<span className="c-red fz-20 fz-22-md ml-5 mr-5">{hotelData?.hotelList.length.toString()}</span>件
                                </div>
                                {(hotelAllData.total) ?
                                <>
                                    <div className="box-sort">
                                        <ul className="box-sort-list">
                                            {target === "ホテル指定" && (
                                              <li key={"result_recommend"} className="box-sort-list-item">
                                                  <button
                                                    className={selectedHotelSort === 0 ? "box-sort-list-item-link button-clear active" : "box-sort-list-item-link button-clear"}
                                                    onClick={() => sortHotels("recommend", 0)}>おすすめ順</button>
                                              </li>
                                            )}
                                            <li key={"result_cheapest"} className="box-sort-list-item">
                                                <button
                                                  className={selectedHotelSort === 1 ? "box-sort-list-item-link button-clear active" : "box-sort-list-item-link button-clear"}
                                                  onClick={() => sortHotels("min_price", 1)}>価格が安い順</button>
                                            </li>
                                            <li key={"result_expensive"} className="box-sort-list-item">
                                                <button
                                                  className={selectedHotelSort === 2 ? "box-sort-list-item-link button-clear active" : "box-sort-list-item-link button-clear"}
                                                  onClick={() => sortHotels("max_price", 2)}>価格が高い順</button>
                                            </li>
                                        </ul>
                                    </div>
                                </> : <></>}
                            </div>
                        </div>
                    ) : StaySearchPageView === 2 && !priceLoading && (
                        // 宿泊プランの検索結果ヘッダー
                        <div className="animation-fade active">
                            <div className="d-f jc-sb ai-c mt-24 mt-20-md mb-16">
                                {planSelectFormData && planSelectFormData?.hotelData && planSelectFormData?.hotelData?.length > 1 && (
                                    <div className="fz-12 fz-14-md fw-b">
                                        <button className="button-clear-blue" onClick={() => {showHotelView()}}>
                                            <i className="icon-left-arrow-light_blue mr-5"></i>宿泊施設の選択に戻る
                                        </button>
                                    </div>
                                )}
                                <div className="box-sort">
                                    <ul className="box-sort-list">
                                        <li key={"plan_cheapest"} className="box-sort-list-item">
                                            <button
                                                className={selectedRoomSort !== "asc" ? "box-sort-list-item-link button-clear" : "box-sort-list-item-link button-clear active"}
                                                onClick={() => sortRoomPlan("asc")}>価格が安い順</button>
                                        </li>
                                        <li key={"plan_expensive"} className="box-sort-list-item">
                                            <button
                                                className={selectedRoomSort !== "desc" ? "box-sort-list-item-link button-clear" : "box-sort-list-item-link button-clear active"}
                                                onClick={() => sortRoomPlan("desc")}>価格が高い順</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}

            {/* 検索結果 */}
            {StaySearchPageView === 1 ? (
                <>
                    {/* 宿泊施設の検索結果 */}
                    <StaySearchResult
                      planSelectFormData={planSelectFormData}
                      selectHotel={selectHotel}
                      isLoading={isLoading}
                      isListLoading={isListLoading}
                      staySearchPageView={StaySearchPageView}
                      setStaySearchPageView={setStaySearchPageView}
                      setRoomTypeFilterState={setRoomTypeFilterState}
                      setMealFilterState={setMealFilterState}
                      setMealFilter={setMealFilter}
                      setRoomTypeFilter={setRoomTypeFilter}
                      hotelAllData={hotelData}
                    ></StaySearchResult>
                </>
            ) : StaySearchPageView === 2 && (
                <>
                    {/* 宿泊プランの検索結果 */}
                    <HotelPlanSearchResult
                        updateSelectHotel={selectPlanSelectFormData}
                        planSelectFormData={planSelectFormData}
                        hotelCode={currentHotelCode}
                        isLoading={isLoading}
                        staySearchPageView={StaySearchPageView}
                        callback={callback}
                        mealFilter={mealFilterState}
                        setMealFilter={setMealFilter}
                        roomTypeFilter={roomTypeFilterState}
                        setRoomTypeFilter={setRoomTypeFilter}
                        hotelAllData={hotelAllData} // ホテル一覧
                        selectedHotel={selectedHotel} // 選択したホテルのデータ
                        selectedRoomSort={selectedRoomSort}
                        showHotelView={showHotelView}
                        setPriceLoading={setPriceLoading}
                        hotelRoomAllController={hotelRoomAllAbortController}
                        hotelRoomController={hotelPlanAbortController}
                    ></HotelPlanSearchResult>

                    {/* モーダル：客室詳細 */}
                    <RoomDetailModal
                        isShow={roomDetailModalState}
                        roomData={undefined}
                        callback={handleRoomDetailModal}
                    />
                </>
            )}
        </>
    );
}
export default StaySearchbox;