import React, {useContext, useEffect, useState} from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {useLocation, useNavigate} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { save } from "../redux/form5Slice";
import { clear as clearForm1 } from "../redux/form1Slice";
import { clear as clearForm2 } from "../redux/form2Slice";
import { clear as clearForm3 } from "../redux/form3Slice";
import { clear as clearForm4 } from "../redux/form4Slice";
import { RootState } from "../redux/store";
import { useApi } from "../utils/useApi";
import { useCalcAge } from "../utils/useCalcAge";
import { useToGenderLabel } from "../utils/useToGenderLabel";

import { InputsForm1 } from "../types/InputsForm1";
import { InputsForm3 } from "../types/InputsForm3";
import { InputsForm4 } from "../types/InputsForm4";
import { InputsForm5 } from "../types/InputsForm5";

//MainTemplate
import MainTemplate from "./MainTemplate";

//scss
import "../scss/common.scss";
import "../scss/component.scss";

//parts import
import FormStep from "./Form/FormStep";
import {PartsCancellationChargeText, PartsTutorialButton} from "./parts"
import {
  SectionParticipant,
  SectionBreakdown,
  SectionSchedule,
  SectionOther,
} from "./section/Form5"
import {PlanSelectFormData} from "../types/planSelectForm";
import {SectionDetail} from "./section/PlanSelectComplete";
import SectionTravelPrice from "./section/SectionTravelPrice";
import {getAge} from "../utils/convert";
import {getReserveRequestParams, isOption, reserveAPI} from "../utils/reserve";
import {Backdrop, CircularProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {getBackSearchURL} from "../utils/search";
import {devLog} from "../utils/errors";
import SectionTravelCampaignConfirm from "./section/OkinawaSaihakken/SectionTravelCampaignConfirm";
import {getHeadTitle} from "../utils/title";
import {FormSessionContext} from "../hooks/FormSession";
import {TagManagerContext, TagManagerType} from "../hooks/GTM";

// lodingのスタイル
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));


const Form5: React.FC = () => {
  const tagManagerContext: TagManagerType = useContext(TagManagerContext);
  const {checkSession} = useContext(FormSessionContext);
  const classesForLoading = useStyles();
  const location = useLocation()
  const [planSelectFormData, setPlanSelectFormData] = useState<{ data: PlanSelectFormData }>(location.state as { data: PlanSelectFormData }) //TODO: need change
  devLog("planSelectFormData", planSelectFormData);
  
  // reduxに保存されているデータをデバッグ
  // for debug
  const savedState = useSelector((state: RootState) => state);
  devLog("redux form1〜4", {
    ...savedState.form1,
    ...savedState.form2,
    ...savedState.form3,
    ...savedState.form4,
  });

  const [ calcAge ] = useCalcAge();

  const [ toGenderLabel ] = useToGenderLabel();

  const navigate = useNavigate();

  const storedForm5 = useSelector((state: RootState) => state.form5.form5);

  const dispatch = useDispatch();

  const savedForm1 = savedState.form1.form1;
  const savedForm2 = savedState.form2.form2;
  const savedForm3 = savedState.form3.form3;
  const savedForm4 = savedState.form4.form4;
  const hidden_contents: string[] = [];
  if (planSelectFormData?.data?.planData?.sellerIndo?.rentalCarSettings?.status === "disabled") {
    hidden_contents.push("rentalCar");
  }

  // 参加者1に表示する人を返す
  const getApplicantForDisplay = () => {
    // お申込者様本人が、代表者として旅行に参加する。
    if (savedForm1.representative.is_applicant_join === "yes") {
      return {
        name: {
          kana: {
            first: savedForm1.applicant.name.kana.first,
            last: savedForm1.applicant.name.kana.last,
          }
        },
        age: calcAge({
          year: savedForm1.applicant.birthday_year,
          month: parseInt(savedForm1.applicant.birthday_month),
          day: parseInt(savedForm1.applicant.birthday_day),
        }),
        gender: savedForm1.applicant.gender,
        email: savedForm1.applicant.email,
      }
    }

    return {
      name: {
        kana: {
          first: savedForm1.representative.name.kana.first,
          last: savedForm1.representative.name.kana.last,
        }
      },
      age: getAge(savedForm1.representative.birthday_year + '-' + savedForm1.representative.birthday_month + "-" + savedForm1.representative.birthday_day),
      gender: savedForm1.representative.gender,
      email: "",
    }
  }

  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    register,
  } = useForm<InputsForm5>({
    defaultValues: storedForm5,
  });

  const [isLoading,setIsLoading] = useState(false);
  // 予約情報をまとめてAPIに投げる
  const onSubmit: SubmitHandler<any> = async (data) => {
    const session = checkSession(planSelectFormData.data);
    if (!session) {
      return;
    }
    setIsLoading(true);
    try {
      const input = {
        form1: savedForm1,
        form2: savedForm2,
        form3: savedForm3,
        form4: savedForm4,
        planSelectFormData: planSelectFormData.data,
        need_consideration: data.other.need_consideration,
        tourist: data.other.tourist
      }
      const reserveResponce = await reserveAPI(input);
      if (reserveResponce.status === 200) {
        // form1〜4の保存データを消去する
        dispatch(clearForm1());
        dispatch(clearForm2());
        dispatch(clearForm3());
        dispatch(clearForm4());
        dispatch(save({ 
          form5: {
            other: {
              need_consideration: data.other.need_consideration,
              agree:data.other.agree,
              tourist: data.other.tourist
            },
            bookingId :reserveResponce?.response?.result?.bookingId ?? ""
          }
        }
        ));
        setIsLoading(false);
        navigate("/Form6",{
          state: {
            data: planSelectFormData.data,
            reserve: reserveResponce.response,
          },
          replace: true
        });
      } else {
        setIsLoading(false);
        reserveErrorAction();
      }

    } catch (error) {
      console.log("api error", error);
      reserveErrorAction();
    }

    setIsLoading(false);
  };
  
  const reserveErrorAction = () => {
    alert("予約処理でエラーが発生しました、申し訳ありませんが時間を置いて再度プランの設定をお願いします。");
    window.location.href = getBackSearchURL(planSelectFormData.data.searchParams, planSelectFormData.data.id);
  }

  let buttonIconLabel = "6";
  if (!isOption(planSelectFormData.data)) {
    buttonIconLabel = "5";
  }

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      tagManagerContext.pageView(getHeadTitle(planSelectFormData.data, "予約最終確認"))
    }, 200)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  return (
    <MainTemplate title={getHeadTitle(planSelectFormData.data, "予約最終確認")} planSelectFormData={planSelectFormData.data}>
      {/* ローディングの表示(APIのレスポンス待ち) */}
      <Backdrop className={classesForLoading.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

        <div className="l-page-content-upper_navi">
          <div className="wrap-content-900">
            <h1 className="l-page-content-upper_navi-heading">予約最終確認</h1>
          </div>
        </div>

        <div className="l-page-content">
          <div className="wrap-content-900">
            <FormStep activeStep={5} isOption={isOption(planSelectFormData.data)}></FormStep>

            {/* 操作ガイドボタン */}
            <PartsTutorialButton value="component-content" />

            <form action="" onSubmit={handleSubmit(onSubmit)}>
              {/* <!--アラート--> */}
              <div className="box-alert-red-small icon-alert">
                <p className="fz-14 fz-16-md c-red fw-b">
                  ご予約後に申込内容の変更はできません。申込内容、旅行者情報のフルネーム、性別・年齢、支払情報などをご確認ください。
                </p>
              </div>

              {/* /参加者情報 */}    
              <SectionParticipant
                calcAge={calcAge}
                toGenderLabel={toGenderLabel}
                applicant={getApplicantForDisplay()}
                followers={savedForm1.followers}
              />

              {/* /行程表 */}
              {/*<SectionSchedule></SectionSchedule>*/}
              <SectionDetail cardClass="card" h2Class="heading-2" planSelectFormData={planSelectFormData.data}></SectionDetail>

              {/* 料金内訳 */}
              <SectionTravelPrice cardNumber={savedForm4.payment.cashcard_number ?? 1234} cardName={savedForm4.payment.cashcard_name} priceData={planSelectFormData.data.priceData}></SectionTravelPrice>

              {/* キャンセル料について */}
              <PartsCancellationChargeText hidden_contents={hidden_contents}></PartsCancellationChargeText>

              {/* その他確認事項について */}
              <SectionOther
                register={register}
                control={control} 
                errors={errors}    
              >
              </SectionOther>

              {/* 全国旅行支援 */}
              <SectionTravelCampaignConfirm
                planSelectFormData={planSelectFormData.data}
                register={register}
                control={control}
                errors={errors}
              ></SectionTravelCampaignConfirm>

              {/* <!--次へ進む--> */}
              <div className="mt-48 mt-64-md">
                <ul className="form-step_button mt-24">
                  <li className="form-step_button-next">
                    {/* <a href="/Form6/" className="button-large-red">
                      <span className="label-number-orange mr-5">6</span>
                      上記内容で予約する
                    </a> */}
                    <button
                      type="submit"
                      className="button-large-red"
                      // todo: class作る
                      style={{
                        display: "block",
                        border: "none",
                        width: "100%",
                      }}
                      // disabled={!isDirty || isSubmitting} // note: これだとhistory.back()で戻ったときにdisabledになる
                      disabled={isSubmitting}
                    >
                      <span className="label-number-orange mr-5">{buttonIconLabel}</span>
                      上記内容で予約する
                    </button>
                  </li>
                  <li className="form-step_button-back">
                    <button
                      type="button"
                      className="button-small-border_gray_blue button-medium-md"
                      onClick={() => {
                        window.history.back();
                      }}
                    >
                      <i className="icon-left_arrow mr-5"></i>
                      決済情報の入力に戻る
                    </button>
                  </li>
                </ul>
              </div>
            
            </form>
          </div>
        </div>

    </MainTemplate>
  );
};

export default Form5;