import {PlanSearch, roomDetail} from "../types/Search";
import {apiClient} from "./apiClient";
import {activityPriceList, IActivity, planStocks, responsePlanStocks} from "../types/Activity";
import {PlanSelectFormData} from "../types/planSelectForm";
import {sellerInfo} from "../types/sller";
import {activityAreaCodes} from "../config/activity";
import {activityReserveAddFieldList, activityReservePriceTypeDetail} from "../types/reserve";
import {activityField} from "../types/InputsForm2";
import {getTotalTravelerNums} from "./search";
import {devLog} from "./errors";
import {comprehensiveItems} from "../types/sellerProducts";


/**
 * アクティビティの在庫があるか
 * @param activity
 * @returns
 */
export const checkActivityStockExist = (activity: IActivity[]) => {
  if (activity.length > 0) {
    for (let i = 0; i < activity.length; i++) {
      if ("stock" in activity[i] && activity[i].stock) {
        return true;
      }
    }
  }

  return false;
}

export const getPlanStocks = async (searchParams:PlanSearch, planCode: string) => {

  const response = await apiClient.post("/plan/stocks", {
    "from_use_date": searchParams.start,
    "to_use_date": searchParams.end,
    "plan_code": planCode
  });
  
  return response.data as responsePlanStocks;
}

export const checkPlanStockListType = (stockList: planStocks["time_stock_list"]):string => {
  if (stockList.length > 0) {
    const checkNoSetTime = stockList.filter((stock) => {
      return stock.time === "no_settime";
    });
    if (checkNoSetTime.length > 0){
      return "no_settime";
    }
  }
  
  return "list";
}

export const activityMinPrice = (activity : IActivity | undefined) => {
  const price = activity?.plan_info?.price_type_list[0]?.price ?? 0;
  return Number(price);
}

export const getActivityPriceCount = (activity: IActivity, priceCode: string) => {
  if (activity.price_list !== undefined) {
    const index = activity.price_list.findIndex((price) => {
      return price.type_code === priceCode;
    });
    
    if (index >= 0) {
      return activity.price_list[index].num;
    }
    
  }
  
  return 0;
}

export const getActivityTotalPrice = (activity: IActivity | undefined) => {
  let total = 0;
  if (activity !== undefined && activity.price_list !== undefined) {
    activity.price_list.forEach((price) => {
      if (price.num !== undefined) {
        total += (price.price * price.num);
      }
    })
  }
  
  return total;
}

/**
 * 基本プラン合計金額計算
 * @param acts
 * @param planSelectFormData
 */
export const getPlansTotalPrice = (acts: IActivity[], planSelectFormData: PlanSelectFormData) => {
  let total = 0;
  const plans = acts;
  const searchParams = planSelectFormData.searchParams;
  const comprehensiveItems = planSelectFormData?.sellerProduct?.comprehensiveItems;
  if (plans.length > 0) {
    plans.forEach((plan) => {
      if (plan.active) {
        const config = getPlanAgeConfig(comprehensiveItems, plan.plan_info.plan_code);
        if (config && plan.price_list !== undefined && plan.price_list.length > 0) {
          const price = Number(plan.price_list[0].price);
          switch (config.group) {
            case "people":
              searchParams.roomDetail.forEach((room) => {
                total += (room.adult * price);
                if (room.child.length > 0) {
                  room.child.forEach((child) => {
                    const maxIgnoreAge = Number(config.oldmax);
                    const minIgnoreAge = Number(config.oldmin);
                    const childAge = child.age;
                    if (maxIgnoreAge < childAge || minIgnoreAge > childAge ) {
                      total += price;
                    }
                  })
                }
              })
              break;
            case "room":
              total += price * searchParams.roomDetail.length;
              break;
            case "group":
              total += price;
              break;
            default:
              break;
          }
          
        }
      }
    })
  }
  
  return total;
}

export const getPlanAgeConfig = (comprehensiveItems: comprehensiveItems[]|undefined, planCode: string) => {
  if (comprehensiveItems !== undefined) {
    const configIndex = comprehensiveItems.findIndex((comprehensive) => {
      return comprehensive.planCode === planCode;
    })
    return comprehensiveItems[configIndex];
  }
  
  return undefined;
}

/**
 * 指定したプランコードの基本プラン設定取得
 * @param comprehensiveItems
 * @param planCode
 */
export const getPlanConfig = (comprehensiveItems: comprehensiveItems[]|undefined, planCode: string) => {
  if (comprehensiveItems !== undefined) {
    const configIndex = comprehensiveItems.findIndex((comprehensive) => {
      return comprehensive.planCode === planCode;
    })
    return comprehensiveItems[configIndex];
  }
  
  return undefined;
}

/**
 * 基本プラン予約人数取得
 * @param roomDetail
 * @param comprehensiveItems
 * @param planCode
 */
export const getPlanPeopleNum = (roomDetail: roomDetail[],comprehensiveItems: comprehensiveItems[]|undefined, planCode: string) => {
  const planAgeConfig = getPlanAgeConfig(comprehensiveItems, planCode);
  let people = 0;
  if (planAgeConfig) {
    roomDetail.forEach((room) => {
      people += room.adult;
      if (room.child.length > 0) {
        room.child.forEach((child) => {
          const maxIgnoreAge = Number(planAgeConfig.oldmax);
          const minIgnoreAge = Number(planAgeConfig.oldmin);
          const childAge = child.age;
          if (maxIgnoreAge < childAge || minIgnoreAge > childAge) {
            people++;
          }
        });
      }
    });
    
    switch (planAgeConfig.group) {
      case "group":
        people = 1;
        break;
      case "room":
        people = roomDetail.length;
        break;
      default:
        break;
    }
  }
  
  return people;
}

/**
 * 基本プラン料金按分用人数取得
 * @param roomDetail
 * @param comprehensiveItems
 * @param planCode
 */
export const getPlanIgnorePeopleNum = (roomDetail: roomDetail[],comprehensiveItems: comprehensiveItems[]|undefined, planCode: string) => {
  const planAgeConfig = getPlanAgeConfig(comprehensiveItems, planCode);
  let people = 0;
  if (planAgeConfig) {
    roomDetail.forEach((room) => {
      people += room.adult;
      if (room.child.length > 0) {
        room.child.forEach((child) => {
          const maxIgnoreAge = Number(planAgeConfig.oldmax);
          const minIgnoreAge = Number(planAgeConfig.oldmin);
          const childAge = child.age;
          if (maxIgnoreAge < childAge || minIgnoreAge > childAge) {
            people++;
          }
        });
      }
    });
  }
  
  return people;
}

export const getPlanAreaCode = (area: string) => {
  
  for (const [key, value] of Object.entries(activityAreaCodes)) {
    if (value === area) {
      return key;
    }
  }

  return "0";
}


export const getActivityGenderCode = (gender: string) => {
  if (gender === "male") {
    return "1";
  }

  return "2";
}

export const getActivityPlanPrice = (priceList: activityPriceList[] | undefined) => {
  let price = 0;
  if (priceList) {
    priceList.forEach((priceData) => {
      const num = priceData.num?? 0;
      price += priceData.price * num;
    })
  }
  
  return price;
}

export const getSelectActivityTimePrices = (activity: IActivity, time_code: string, date: string) => {
  if (activity.stocks && activity.stocks.length > 0) {
    const selectStockIndex = activity.stocks.findIndex((stock) => {
      return stock.use_date === date && stock.time_stock_list.some((timeList) => {
        return timeList.time_code === time_code;
      });
    })
    if (selectStockIndex >= 0) {
      return [...activity.stocks[selectStockIndex].price_type_list];
    }
  }
  
  return [];
}

export const getPlanSelectPeopleType = (planData: IActivity, comprehensiveItems: comprehensiveItems[]|undefined) => {
  if (comprehensiveItems) {
    const comprehensiveItem = comprehensiveItems.find((comprehensive) => {
      return comprehensive.planCode === planData.plan_info.plan_code;
    });
    
    return comprehensiveItem?.selectPeopleType ?? "auto";
  }
  
  return 'auto'
}

export const setPlanReservePriceTypeDetail = (planData: IActivity) => {
  const price_type_detail:activityReservePriceTypeDetail[] = [];
  planData.price_list?.forEach((priceList) => {
    const num = priceList.num?? 0;
    if (num > 0) {
      const subTotal = priceList.price * num;
      price_type_detail.push({
        type_code: priceList.type_code,
        name: priceList.name,
        num: num,
        subtotal_price: subTotal
      });
    }
  });
  
  return price_type_detail;
}


/**
 * アクティビティの追加項目値を設定
 * @param planData
 * @param form2Inputs
 */
export const setPlanReserveAddFieldList = (planData: IActivity, form2Inputs:activityField) => {
  const addFieldList:activityReserveAddFieldList[] = [];
  const planCode = planData.plan_info.plan_code;
  planData.price_list?.forEach((priceList) => {
    const num = priceList.num?? 0;
    const priceCode = priceList.type_code;
    if (planData.plan_info.add_field_list) {
      planData.plan_info.add_field_list.forEach((filed) => {
        const filedCode = filed.field_code;
        if (num > 0) {
          if (filed.multiple_display_type === "0") {
            devLog("form2Inputs", form2Inputs);
            const filedValue = getReserveFormFieldData(planCode, filedCode, form2Inputs)
            if (filedValue){
              addFieldList.push({
                field_code: filedCode,
                field_name: filed.field_name,
                price_type_code: priceCode,
                number: 0,
                option_key: filedValue,
              });
            }
          }
          if (filed.multiple_display_type === "1") {
            for (let n = 0; n < num; n++) {
              const filedValue = getReserveFormMultipleFieldsData(planCode, filedCode, form2Inputs, priceCode, n)
              if (filedValue) {
                addFieldList.push({
                  field_code: filed.field_code,
                  field_name: filed.field_name,
                  price_type_code: priceCode,
                  number: n + 1,
                  option_key: filedValue,
                });
              }
            }
          }
        }
      })
    }
  })
  
  return addFieldList;
}

/**
 * 追加オプションの追加項目値を設定
 * @param planData
 * @param form2Inputs
 */
export const setOptionsReserveAddFieldList = (planData: IActivity, form2Inputs:activityField, index: number) => {
  const addFieldList:activityReserveAddFieldList[] = [];
  const planCode = planData.plan_info.plan_code;
  planData.price_list?.forEach((priceList) => {
    const num = priceList.num?? 0;
    const priceCode = priceList.type_code;
    if (planData.plan_info.add_field_list) {
      planData.plan_info.add_field_list.forEach((filed) => {
        const filedCode = filed.field_code;
        if (num > 0) {
          if (filed.multiple_display_type === "0") {
            devLog("form2Inputs", form2Inputs);
            const filedValue = getReserveFormOptionsFieldData(planCode, filedCode, form2Inputs, index)
            if (filedValue){
              addFieldList.push({
                field_code: filedCode,
                field_name: filed.field_name,
                price_type_code: priceCode,
                number: 0,
                option_key: filedValue,
              });
            }
          }
          if (filed.multiple_display_type === "1") {
            for (let n = 0; n < num; n++) {
              const filedValue = getReserveFormMultipleOptionsFieldsData(planCode, filedCode, form2Inputs, priceCode, n, index)
              if (filedValue) {
                addFieldList.push({
                  field_code: filed.field_code,
                  field_name: filed.field_name,
                  price_type_code: priceCode,
                  number: n + 1,
                  option_key: filedValue,
                });
              }
            }
          }
        }
      })
    }
  })
  
  return addFieldList;
}

export const getReserveFormFieldData = (planCode:string, fieldCode: string, form2Inputs:activityField) => {
  if (form2Inputs[planCode] !== undefined) {
    const selectPlan = form2Inputs[planCode]["fields"];
    if (selectPlan[fieldCode] !== undefined) {
      const value = selectPlan[fieldCode];
      if (typeof value === "object") {
        return  value.join('|');
      }
      return selectPlan[fieldCode];
    }
  }
  
  return undefined;
}

export const getReserveFormOptionsFieldData = (planCode:string, fieldCode: string, form2Inputs:activityField, index: number) => {
  if (form2Inputs[index] && form2Inputs[index][planCode] !== undefined) {
    const selectPlan = form2Inputs[index][planCode]["fields"];
    if (selectPlan[fieldCode] !== undefined) {
      const value = selectPlan[fieldCode];
      if (typeof value === "object") {
        return  value.join('|');
      }
      return selectPlan[fieldCode];
    }
  }
  
  return undefined;
}

export const getReserveFormMultipleFieldsData = (planCode:string, fieldCode: string, form2Inputs:activityField, priceCode:string, people:number) => {
  devLog("planCode", planCode);
  devLog("fieldCode", fieldCode);
  devLog("form2Inputs", form2Inputs);
  if (form2Inputs[planCode] !== undefined) {
    devLog("selectPlan[fieldCode]", form2Inputs[planCode]);
    const selectPlan = form2Inputs[planCode]["multiple"];
    if (selectPlan[priceCode] !== undefined && selectPlan[priceCode][people][fieldCode]) {
      devLog("selectPlan[fieldCode]", selectPlan[priceCode]);
      const value = selectPlan[priceCode][people][fieldCode];
      if (typeof value === "object") {
        return  value.join('|');
      }
      return selectPlan[priceCode][people][fieldCode];
    }
  }

  return undefined;
}

export const getReserveFormMultipleOptionsFieldsData = (planCode:string, fieldCode: string, form2Inputs:activityField, priceCode:string, people:number, index: number) => {
  devLog("planCode", planCode);
  devLog("fieldCode", fieldCode);
  devLog("form2Inputs", form2Inputs);
  if (form2Inputs[index] && form2Inputs[index][planCode] !== undefined) {
    devLog("selectPlan[fieldCode]", form2Inputs[index][planCode]);
    const selectPlan = form2Inputs[index][planCode]["multiple"];
    if (selectPlan[priceCode] !== undefined && selectPlan[priceCode][people][fieldCode]) {
      devLog("selectPlan[fieldCode]", selectPlan[priceCode]);
      const value = selectPlan[priceCode][people][fieldCode];
      if (typeof value === "object") {
        return  value.join('|');
      }
      return selectPlan[priceCode][people][fieldCode];
    }
  }

  return undefined;
}

export const getActivityUseTime = (use_time: string) => {
  if(use_time && use_time !== "no_settime"){
    return use_time;
  }
  return "";
}

export const hasActivityStock = (activity: IActivity) => {
  if (!activity.stock) {
    return false;
  }
  
  activity.plan_info.price_type_list.forEach((priceList) => {
    if (!priceList.price && priceList.price <= 0) {
      return false;
    }
  });
  
  return true;
}

export const isPlanPriceType = (planSelectFormData: PlanSelectFormData, activity: IActivity) => {
  if (activity.plan_info.price_type_list && activity.plan_info.price_type_list.length > 0) {
    const priceList = activity.plan_info.price_type_list[0];
    const comprehensiveItems = planSelectFormData?.sellerProduct?.comprehensiveItems;
    const people = getPlanPeopleNum(planSelectFormData.searchParams.roomDetail, comprehensiveItems, activity.plan_info.plan_code);
    const minPeople = Number(priceList.min_people);
    const maxPeople = Number(priceList.max_people);

    return people >= minPeople && people <= maxPeople;
  }
  
  return false;
}

export const isPlanTimeStocks = (planSelectFormData: PlanSelectFormData, activity: IActivity, stocks: responsePlanStocks) => {
  const people = getPlanPeopleNum(planSelectFormData.searchParams.roomDetail, planSelectFormData?.sellerProduct?.comprehensiveItems, activity.plan_info.plan_code);
  const findStock = stocks.detail.findIndex((stockDetail) => {
    if (stockDetail.time_stock_list.length > 0) {
      const findTimeStock = stockDetail.time_stock_list.findIndex((stockList) => {
        return stockList.stock >= people;
      });
      return findTimeStock >= 0;
    }
    return true;
  })

  return findStock >= 0;

}

/**
 * 設定したアクティビティの値が最低人数以上になっているか
 * @param activity
 */
export const activityPeopleMinData = (activity: IActivity) => {
  const price_list = activity.price_list;
  if (price_list) {
    return price_list.filter((price) => {
      const num = price.num ?? 0;
      return num !== 0 && num < price.min_people;
    });
  }
  return [];
}
export const activityPeopleMaxData = (activity: IActivity) => {
  const price_list = activity.price_list;
  if (price_list) {
    return price_list.filter((price) => {
      const num = price.num ?? 0;
      return num !== 0 && num > price.max_people;
    });
  }
  return [];
}

export const checkReserveOptionsSelectDate = (useActivity: IActivity | undefined, reserveOptions: PlanSelectFormData['reserveOptions']) => {
  if (useActivity && reserveOptions && reserveOptions.length > 0) {
    const checkOption = reserveOptions.filter((option) => {
      if (useActivity?.timeType && useActivity.timeType === 'time' ) {
        return option.date === useActivity.date && 
            option.plan_info.plan_code === useActivity.plan_info.plan_code && 
            option.time_code === useActivity.time_code &&
            option.time === useActivity.time;
      }
      return option.date === useActivity.date && option.plan_info.plan_code === useActivity.plan_info.plan_code;
    });
    
    if (checkOption.length > 0) {
      return true;
    }
  }
  
  return false;
}