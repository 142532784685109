import React, {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom"

//パーツをインポート
import {PartsStep, PartsTutorialButton,} from "./parts"
import {SectionFlight, SectionPlan, SectionStay,} from "./section/PlanSelectTop"
import {SearchNotFound} from "./"

import TopSearchbox from "./search/TopSearchbox";

// MainTemplate
import MainTemplate from "./MainTemplate";

// scss
import "../scss/common.scss";
import "../scss/component.scss";

// for api
import {apiClient} from "../utils/apiClient";

// query parser
import qs from 'qs';

// types
import {IActivity} from "../types/Activity";
import {PlanSearch, searchResult} from "../types/Search";
import { AxiosResponse } from "axios";

// フライト
import {
  Flights,
  FlightType,
  FlightEntity,
  reservationFlight,
  viaReservationFlight,
  ReservationFlight,
  ViaReservationFlight,
  AirLines,
  FReservationFlightEntity,
  TravelType,
  Airline
} from "../types/Flight";

// ホテル
import {HotelAllSearchResponse, hotelSearchQuery, searchHotelParams, selectHotelDetail} from "../types/Hotel";
import {PlanSelectFormData, PlanSelectFromHotelType, PlanSelectFromFlightType} from "../types/planSelectForm";
import {FreeSpace} from "../types/Freespace";


// lib
import {dateFormat, rangeDate} from "../utils/convert"
import { ContactSupportOutlined } from "@material-ui/icons";
import {Seller} from "../types/seller";
import {
  getFlightCodeLabel,
  isNotCurrentFlights, checkUpdatedSelectFlights, getArrivalAirports, isFlightsLoading, isVacancy,
} from "../utils/flight";
import {
  checkHotel, getHotel,
  getHotelAreaCode,
  getHotelRoomPeopleLabel, getHotelSearchParams,
  scrollToHotelSection,
  sortRecommendHotels
} from "../utils/hotel";
import {checkActivityStockExist} from "../utils/plan";
import {sellerInfo} from "../types/sller";
import {defaultPlanSelectForm} from "../defaults/palnSelectForm";
import {checkSearchResult, getPlanSelectInfoData, initSearchParams} from "../utils/search";
import freeSpace from "./FreeSpace";
import FreeSpaceSection from "./FreeSpace";
import LoadingOverlay from "react-loading-overlay-ts";
import {getPlansTotalPrice, hasActivityStock} from "../utils/plan";
import {defaultBasicTotalPrice, getBasicTotalPrice} from "../utils/price";
import {actionAirline, airCode, anaHasNoFlightAirCode, jalHasNoFlightAirCode} from "../config/Airport";
import Error500 from "./error/Error500";
import ServiceStop from "./ServiceStop";
import _ from "lodash";

import {getSearchURL} from "../utils/search";
import {validateFlightStartDate} from "../utils/validate";
import {devLog} from "../utils/errors";
import { sellerProductsType } from "../types/sellerProducts";
import {
  getCheepsFlight,
  getSearchFlightsRequestParams,
  sortFlights,
  getSelectedFlights, isChangeAirport,
  filterFlightTime
} from "../utils/flightV2";
import {searchFlights} from "../utils/useFlightApi";
import {
  currentFlightEntity,
  flightEntity,
  flightsEntity, FReservationFlight,
} from "../types/FlightV2";
import {defaultFlightEntities} from "../defaults/flight";
import { animateScroll as scroll } from 'react-scroll';
import {getHeadTitle} from "../utils/title";
import {FormSessionContext, FormSessionType} from "../hooks/FormSession";
import dayjs from "dayjs";
import {TagManagerContext, TagManagerType} from "../hooks/GTM";
import {siteTitle} from "../config/main";
import {getHotelSort} from "../utils/hotel/getHotelSort";


const PlanSelectPage: React.FC = () => {
  const tagManagerContext: TagManagerType = useContext(TagManagerContext);
  // use navigate
  const navigate = useNavigate();

  // 検索ボックスのパラメータ
  const search = useLocation().search.slice(1);
  const [searchParams, setSearchParams] = useState<PlanSearch>(initSearchParams(search));
  const [searchLoading, setSearchLoading] = useState(true);
  const [planType, setPlanType] = useState("");
  let notFound = false;
  if (!validateFlightStartDate(searchParams.start)) {
    notFound = true;
  }

  // is loading for any sections, you can add new loading value for section
  const [isLoadingComprehenshive, setLoadingComprehenshive] = useState(false);
  const [isLoadingFlight, setLoadingFlight] = useState({
    all: true,
    ANA: true,
    JAL: true
  });
  const [isLoadingHotel, setLoadingHotel] = useState(true);
  const [isListLoading, setIsListLoading] = useState(false);
  const [planSelectFormData, setPlanSelectFormData] = useState<PlanSelectFormData>(defaultPlanSelectForm);
  const [is500Error, setIs500Error] = useState(false);
  const [isNotFound, setIsNotFound] = useState(notFound);
  const [isPlan, setIsPlan] = useState(true);

  const [logo, setLogo] = useState<string|undefined>(undefined);

  // get url params
  const urlParams = useParams<{ id: string }>();

  // 包括オプション
  const [activities, setActivities] = useState<IActivity[]>([]);

  // 宿泊施設(ホテル)
  const [actionHotel, setActionHotel] = useState(false);
  const [hotelAllData, setHotelAllData] = useState<HotelAllSearchResponse>({
    total: 0,
    currentPage: 0,
    totalPage: 0,
    search_sort: 0,
    status: 0,
    success: true,
    error: undefined,
    hotelList: [],
    subAreaCodes: "",
    hotelTypeCodes: "",
  }); // ホテル一覧で取得したデータ
  const [actionInitHotel, setActionInitHotel] = useState(false);
  const [updateHotelSearchParams, setHotelSearchParams] = useState<hotelSearchQuery>({
    area_code: '',
    target: '',
    code: '',
    search_sort: 0,
    query: searchParams,
  })


  // フライトと宿泊の表示非表示（選択できるできないのhandle）
  const [canSelect, setCanSelect] = useState(false);
  const [sellerId, setSellerId] = useState<string|undefined>(undefined);
  const [sellerInfo, setSellerInfo] = useState<sellerInfo|undefined>(undefined);

  // entities
  const [currentFlightEntities, setCurrentFlightEntities] = useState<currentFlightEntity[]>([])
  const [flightEntities, setFlightEntities] = useState<flightsEntity>(defaultFlightEntities)

  // 各航空会社が就航していない区間ならtrueになる
  const [ anaHasNoFlight, setAnaHasNoFlight ] = useState(false);
  const [ jalHasNoFlight, setJalHasNoFlight ] = useState(false);
  const [ canFlight, setCanFlight ] = useState(false);
  const [ currentAirline, setCurrentAirline ] = useState("ANA");


  // ホテル検索のエリア指定で使用する
  const [selectedHotelAreaCode, setSelectedHotelAreaCode] = useState<string[]>([]);
  const [selectedHotelTypeCode, setSelectedHotelTypeCode] = useState<string[]>([]);
  
  const sessionContext: FormSessionType = useContext(FormSessionContext);

  // 検索
  const handleSearch = (q: PlanSearch) => {
    setSearchParams({...q})
    window.location.href = getSearchURL(q);
  }

  /**
   * フリースペース
   */
  const [freeSpaceContent, setFreeSpaceContent] = React.useState<FreeSpace>();
  useEffect(() => {
    // フリースペース取得（ローディング無し）
    (async () => {
      if (!planSelectFormData.freeSpace && searchParams.to !== "") {
        const response = await apiClient.post("/freeSpace", JSON.stringify({
          "plan": urlParams.id,
          isServiceInsurance: planSelectFormData.planData?.product?.serviceInsuranceSettings?.status === "enabled" ?? true,
          serviceInsurance: {
            arrivalAirport: searchParams.to,
            tripStartDate: searchParams?.start
          }
        }))
          .then(function (response) {
            const data = response.data.freeSpace;
            const adminData = response.data.adminFreeSpace;
            const okinawaSaihakkenData = response.data.okinawaSaihakkenFreeSpace;
            const serviceInsuranceData = response.data.serviceInsuranceFreeSpace
            const updatePlanSelectFormData = planSelectFormData
            if (data || adminData || okinawaSaihakkenData) {
              updatePlanSelectFormData.freeSpace = {
                freeSpace: data.body,
                adminFreeSpace: adminData.body
              }

              // 全国割のフリースペース表示設定
              if (okinawaSaihakkenData.body.freeSpaceStatus === "enabled") {
                // 導入事業者別の表示設定
                if (planSelectFormData.planData?.sellerIndo?.discountSettings?.okinawaSaihakkenFreeSpace === "enabled") {
                  updatePlanSelectFormData.freeSpace.okinawaSaihakkenFreeSpace = okinawaSaihakkenData.body;
                }
              }
              
              if (serviceInsuranceData) {
                updatePlanSelectFormData.freeSpace.serviceInsuranceFreeSpace = serviceInsuranceData;
              }

              setFreeSpaceContent({...updatePlanSelectFormData.freeSpace})
              setPlanSelectFormData((prevState) => (
                {
                  ...prevState,
                  freeSpace: updatePlanSelectFormData.freeSpace,
                }))
            }
          })
          .catch(function (error) {
            // TODO: handle error
            setIs500Error(true);
            console.log(error);
          });
      }
    })();
  },[searchParams])

  /**
   * 初回実行処理
   */
  useEffect(() => {

    setPlanSelectFormData((prevState) => (
      {...prevState,
        id : urlParams.id?? "",
        info: getPlanSelectInfoData(searchParams),
        searchParams: searchParams,
        timestamp: dayjs().format()
      }
    ));

    (async () => {
      if(activities.length <= 0) {
        setLoadingComprehenshive(true)
        const response = await apiClient.post("/plan", JSON.stringify({
          "plan" : urlParams.id,
          "use_date" : searchParams?.start,
          "end_date" : searchParams?.end,
          "to": searchParams?.to,
        }))
          .then(function (response) {
            setLoadingComprehenshive(false);
            const results = response.data['plans'];
            const resultsOptions = response.data['options'];
            const data = results as IActivity[];
            if (response.data.info.status === 200) {
              const logoImage = response.data.sellerIndo.companyInfo.logo;
              const type = response.data.info.body.type?? "option";
              const arrival = response.data.sellerIndo.useAirport.arrival;
              setLogo(logoImage);
              searchParams.to = arrival;
              searchParams.c_airport_from = arrival;

              // 基本プランに商品が設定されている場合、商品のデータを使用する
              if (response.data.product) {
                const productData: sellerProductsType = response.data.product;
                searchParams.to = productData.useAirport.arrival;
                searchParams.from = productData.useAirport.departure ?? "HND";
                searchParams.c_airport_from = productData.useAirport.arrival;
              }

              setPlanSelectFormData((prevState) => (
                {
                  ...prevState,
                  activity: data,
                  planData: response.data,
                  options: resultsOptions,
                  logo: logoImage?? "",
                  planType: type,
                  searchParams:searchParams,
                  sellerProduct: response.data.product,
                  isCoupons: response.data.isCoupons
                }))
              setActivities(data);
              setSellerId(response.data.info.body.userId)
              setPlanType(type);
              setSellerInfo(response.data.sellerIndo);
              setSearchParams({...searchParams});
              if (type === "option") {
                setCanSelect(true);
              }
              if (response.data.sellerIndo.active === '停止中') {
                setIsPlan(false);
              }
            } else {
              setIsPlan(false);
            }
          })
          .catch(function (error) {
            // TODO: handle error
            console.log(error);
            setIs500Error(true);
            setLoadingComprehenshive(false);
          });
      }
    })();

  }, []);
  
  useEffect(() => {
    if (planSelectFormData && sellerId) {
      const title = getHeadTitle(planSelectFormData, undefined)
      if (title !== siteTitle) {
        const title = getHeadTitle(planSelectFormData, undefined)
        tagManagerContext.pageView(title)
      }
    }
  }, [sellerId])

  /**
   * フライト検索
   */
  useEffect(() => {
    setPlanSelectFormData((prevState) => (
      {...prevState,
        info: getPlanSelectInfoData(searchParams)
      }
    ));
    // 基本プラン取得後にフライト情報取得
    if (searchParams !== undefined && planSelectFormData.planData !== undefined && currentFlightEntities.length === 0) {
      setActionHotel(true);
      (async () => {
        setSearchLoading(false);
        await updateFlights(undefined, searchParams);
        setLoadingFlight((prevState) => ({
          ...prevState,
          all: false,
        }))
      })();
    }
  }, [searchParams])

  /**
   * フライト情報取得
   */
  const updateFlights = async (notUpdateFlight: {action: string, airline: string, currentFlights:FReservationFlight[]} | undefined, search: PlanSearch) => {
    let _currentFlightEntities = currentFlightEntities;
    const useAirline = planSelectFormData.sellerProduct?.useAirlineSettings?.status ?? 'all';
    
    if (useAirline === 'JAL') {
      setCurrentAirline('JAL');
    }
    
    for(const [index, airline] of actionAirline.entries()) {
      
      // フライト情報取得
      const flights: { inbound: flightEntity, outbound: flightEntity } = {
        inbound: {
          flights: [],
          cheepsIndex: 0
        },
        outbound: {
          flights: [],
          cheepsIndex: 0
        }
      }
      
      if ( useAirline === 'all' || airline.name === useAirline) {
        const flightsRequestPrams = getSearchFlightsRequestParams(search, airline.name);
        const response = await searchFlights(flightsRequestPrams);

        if (response.status === 200) {
          // 日程ソート
          flights.inbound.flights  = sortFlights(response.inbound?.FReservationFlights);
          flights.outbound.flights = sortFlights(response.outbound?.FReservationFlights);

          // フライト時間制御チェック(絞り込み)
          const filteredInboundFlights = filterFlightTime(flights.inbound.flights, planSelectFormData.sellerProduct?.arrivalTimeControl, "arrival");
          const filteredOutboundFlights = filterFlightTime(flights.outbound.flights, planSelectFormData.sellerProduct?.departureTimeControl, "departure");
          flights.inbound.flights = filteredInboundFlights;
          flights.outbound.flights = filteredOutboundFlights;

          // 最安の取得
          const inboundCheeps = getCheepsFlight(airline.name, flights.inbound.flights,search.roomDetail);
          flights.inbound.cheepsIndex = inboundCheeps.index;
          const outboundCheeps = getCheepsFlight(airline.name, flights.outbound.flights,search.roomDetail);
          flights.outbound.cheepsIndex = outboundCheeps.index;
        }
      }
      
      let currentFlight: currentFlightEntity[] = [];

      switch (airline.name) {
        case "ANA":
          if (flights.inbound.flights.length > 0 && flights.outbound.flights.length > 0) {
            const inboundVacancy = isVacancy(airline.name, flights.inbound.flights[flights.inbound.cheepsIndex].FReservationFlight[0], search.roomDetail);
            const outboundVacancy = isVacancy(airline.name, flights.outbound.flights[flights.outbound.cheepsIndex].FReservationFlight[0], search.roomDetail);
            if (inboundVacancy && outboundVacancy) {
              setFlightEntities(prevState => ({...prevState,
                ANA: flights
              }));
              _currentFlightEntities = updateCurrentFlightEntities(flights, airline.name, notUpdateFlight, _currentFlightEntities, useAirline);
              setAnaHasNoFlight(false);
            } else {
              setAnaHasNoFlight(true);
            }
          } else {
            _currentFlightEntities.push({
              airline: airline.name,
              inbound: [],
              outbound: [],
              current: false
            })
            setAnaHasNoFlight(true);
          }
          break;
        case "JAL":
          if (flights.inbound.flights.length > 0 && flights.outbound.flights.length > 0) {
            const inboundVacancy = isVacancy(airline.name, flights.inbound.flights[flights.inbound.cheepsIndex].FReservationFlight[0], search.roomDetail);
            const outboundVacancy = isVacancy(airline.name, flights.outbound.flights[flights.outbound.cheepsIndex].FReservationFlight[0], search.roomDetail);
            if (inboundVacancy && outboundVacancy) {
              setFlightEntities(prevState => ({...prevState,
                JAL: flights
              }));
              _currentFlightEntities = updateCurrentFlightEntities(flights, airline.name, notUpdateFlight, _currentFlightEntities, useAirline);
              setJalHasNoFlight(false);
            } else {
              setJalHasNoFlight(true);
            }
          } else {
            _currentFlightEntities.push({
              airline: airline.name,
              inbound: [],
              outbound: [],
              current: false
            })
            setJalHasNoFlight(true);
          }
          break;
        default:
          break;
      }

      const selectFlights = getSelectedFlights(_currentFlightEntities);
      setPlanSelectFormData((prevState) => ({
        ...prevState,
        flight: selectFlights,
      }))

      if (selectFlights && selectFlights.length > 0) {
        setCanFlight(true);
      }
      setLoadingFlight(isFlightsLoading(_currentFlightEntities));

    }
  }

  const updateCurrentFlightEntities = (flights: { inbound: flightEntity, outbound: flightEntity }, airline: string, notUpdateFlight: {action: string, airline: string, currentFlights:FReservationFlight[]} | undefined, _currentFlightEntities: currentFlightEntity[], useAirline?: string): currentFlightEntity[] => {
    if (!notUpdateFlight) {
      const useCurrentAirline = useAirline === 'all' ? 'ANA' : useAirline;
      const inboundFlights = flights.inbound.flights[flights.inbound.cheepsIndex].FReservationFlight;
      const outboundFlights = flights.outbound.flights[flights.outbound.cheepsIndex].FReservationFlight;
      let current = false;
      if (airline === useCurrentAirline && inboundFlights.length > 0 && outboundFlights.length) {
        current = true;
      } else if (_currentFlightEntities.length === 0 && inboundFlights.length > 0 && outboundFlights.length) {
        current = true;
      }
      _currentFlightEntities.push({
        airline: airline,
        inbound: [flights.inbound.flights[flights.inbound.cheepsIndex].FReservationFlight[0]],
        outbound: [flights.outbound.flights[flights.outbound.cheepsIndex].FReservationFlight[0]],
        current: current
      })
      return  _currentFlightEntities;

    } else {
      const updateCurrentFlightEntities = _currentFlightEntities.map((flightEntities) => {
        if (notUpdateFlight && flightEntities.airline === airline && flightEntities.airline === notUpdateFlight.airline) {
          return {
            ...flightEntities,
            inbound: notUpdateFlight.action === 'inbound'?  notUpdateFlight.currentFlights : flights.inbound.flights[flights.inbound.cheepsIndex].FReservationFlight,
            outbound: notUpdateFlight.action === 'outbound'? notUpdateFlight.currentFlights : flights.outbound.flights[flights.outbound.cheepsIndex].FReservationFlight,
            current: flightEntities.airline === currentAirline,
          };
        } else if (flightEntities.airline === airline)  {
          return {
            ...flightEntities,
            inbound: flights.inbound.flights[flights.inbound.cheepsIndex].FReservationFlight,
            outbound: flights.outbound.flights[flights.outbound.cheepsIndex].FReservationFlight,
            current: flightEntities.airline === currentAirline,
          };
        }
        return {
          ...flightEntities,
          current: flightEntities.airline === currentAirline,
        };
      });
      setCurrentFlightEntities([...updateCurrentFlightEntities]);

      return updateCurrentFlightEntities;
    }

  }

  /**
   * 選択航空会社変更
   * @param airline
   */
  const changeAirportCallback = (airline: string) => {
    setCurrentAirline(airline);
    const updateFlightEntities = currentFlightEntities.map((flightEntities)=> {
      return {
        ...flightEntities,
        current: flightEntities.airline === airline,
      }
    });
    setCurrentFlightEntities([...updateFlightEntities]);
    const selectFlights = getSelectedFlights(updateFlightEntities);
    setPlanSelectFormData((prevState) => ({
      ...prevState,
      flight: selectFlights,
    }))
  }

  /**
   * 選択フライト変更
   * @param airline
   * @param action
   * @param currentFlights
   * @param airport
   */
  const handleFlightSelectCallback = (airline:string, action:string, currentFlights:FReservationFlight[], airport: string | null | undefined ) => {
    // 到着空港が変更の場合は更新
    if (isChangeAirport(searchParams, action, airport)) {
      setLoadingFlight({all: true, ANA: true, JAL: true});
      const search = searchParams;
      search.from = airport ?? searchParams.from;
      setSearchParams({...search});

      (async () => {
        await updateFlights({airline: airline, action: action, currentFlights: currentFlights}, search);
        setLoadingFlight((prevState) => ({
          ...prevState,
          all: false,
        }))
      })();
    } else {
      const updateFlightEntities = currentFlightEntities.map((flightEntities)=> {
        if (flightEntities.airline === airline) {
          if (action === 'inbound') {
            return {
              ...flightEntities,
              inbound: currentFlights,
              current: flightEntities.airline === airline,
            }
          } else {
            return {
              ...flightEntities,
              outbound: currentFlights,
              current: flightEntities.airline === airline,
            }
          }
        }

        return flightEntities
      });
      setCurrentFlightEntities([...updateFlightEntities]);
      const selectFlights = getSelectedFlights(updateFlightEntities);
      setPlanSelectFormData((prevState) => ({
        ...prevState,
        flight: selectFlights,
      }))
    }
  }

  /**
   * ホテル検索（初回）
   */
  useEffect(() => {
    /**
     * ホテル情報取得
     */
    if (sellerInfo !== undefined && searchParams !== undefined && actionHotel) {
      let target = sellerInfo.hotelSettings.target;
      let code = sellerInfo.hotelSettings.hotelCode;
      const hotelAreaCode = getHotelAreaCode(planSelectFormData);

      // 基本プランに商品が設定されている場合、商品のデータを使用する
      if (planSelectFormData.sellerProduct) {
        target = planSelectFormData.sellerProduct.hotelSettings.target;
        code = planSelectFormData.sellerProduct.hotelSettings.hotelCode;
      }

      // ホテル（宿泊施設）
      if (hotelAllData.currentPage === 0) {
        let searchSort = 1;
        if (target === 'ホテル指定') {
          searchSort = getHotelSort(planSelectFormData?.sellerProduct?.hotelSettings.sort ?? '');
        }

        setHotelSearchParams((prevState) => ({
          ...prevState,
          search_sort: searchSort
        }))
        searchHotelList({
          area_code: hotelAreaCode,
          target: target,
          code: code,
          query: searchParams,
          paged: 1,
          search_sort: searchSort,
        });
      }
    }
  }, [actionHotel]);

  /**
   * 基本料金計算処理実行
   */
  useEffect(() => {
    if (hotelAllData && canSelect && canFlight) {
      updateBasicTotalPrice();
    }
  },[hotelAllData, canSelect, planSelectFormData.flight, canFlight, planSelectFormData.hotel, planSelectFormData.activity])

  /**
   * 基本料金更新処理
   */
  const updateBasicTotalPrice = () => {
    if (canSelect && planSelectFormData.flight && planSelectFormData.flight.length > 0 && planSelectFormData.hotel.length > 0) {
      const total = defaultBasicTotalPrice(planSelectFormData);
      if (total > 0) {
        setPlanSelectFormData((prevState) => ({...prevState, basicPriceTotal:total}))
        setLoadingHotel(false);
      }
    } else if (canSelect && (!planSelectFormData.hotel || planSelectFormData.flight.length === 0)) {
      setIsNotFound(true)
    }
  }

  /**
   * 基本プラン(アクティビティ)設定更新
   * @param acts
   */
  const setActivityCallBack = (acts: IActivity[]) => {
    setActivities(acts)
    setActivityStockExist(checkActivityStockExist(acts));
    setPlanSelectFormData((prevState) => ({...prevState, activity:acts}))
    const active = acts.filter((item) => item.active === true);
    const planTotal = getPlansTotalPrice(acts, planSelectFormData);

    setPlanSelectFormData((prevState) => ({...prevState, planTotal:planTotal}))
    // フライト非表示解除チェック
    let activeSelectCheck = false
    const noSelectActivity = acts.find((activity) => {
      return !activity.active && hasActivityStock(activity)
    })
    // 読み込み中のアクティビティがないかチェック
    const loadingActivity = acts.find((activity) => {
      return activity.loading
    });
    if (!noSelectActivity && !loadingActivity) {
      activeSelectCheck = true;
    }
    setCanSelect(activeSelectCheck)
  }

  const [activityStockExist, setActivityStockExist] = useState(true);

  /**
   * 予約 確認・変更画面に遷移
   * @param planSelectFormData
   */
  const handleMove = (planSelectFormData:PlanSelectFormData) => {
    /**
     * 基本料金設定（データが変更されるたびに再計算）
     */
    if (planSelectFormData.flight.length > 0 && planSelectFormData.hotelTotal > 0) {
      const total = defaultBasicTotalPrice(planSelectFormData);
      if (total > 0) {
        setPlanSelectFormData((prevState) => ({...prevState, basicPriceTotal:total, searchParams: searchParams}))
        planSelectFormData.basicPriceTotal = total;
        planSelectFormData.searchParams = searchParams;
      }
    }

    if(planSelectFormData.hotel.length > 0 && planSelectFormData.hotel[0].plan !== undefined) {
      sessionContext.checkSession(planSelectFormData);
      navigate("/planselectconfirm", { state: {
          data: planSelectFormData
        } })
    }
  }

  /**
   * 並び順を指定してホテル検索
   * @param type 
   * @param val 
   * @param areaCode 
   * @param hotelType 
   */
  const hotelSort = (type: string, val: number) => {
    if (isLoadingHotel) {
      return;
    }
    if (sellerInfo !== undefined && searchParams !== undefined) {
      const hotelAreaCode = getHotelAreaCode(planSelectFormData);
      const hotelSearchParams = {
        sort: 1,
        target: sellerInfo.hotelSettings.target,
        code: sellerInfo.hotelSettings.hotelCode,
        searchKeyWordType: '',
        searchKeyWordMode: 1,
        searchKeyWordCode: '',
        searchHotelType: '',
      }
      switch (type) {
        case "max_price":
          hotelSearchParams.sort = 2;
          break;
        case "min_price":
          hotelSearchParams.sort = 1;
          break;
        case "recommend":
          hotelSearchParams.sort = 0;
          break;
        default:
          break;
      }

      // 基本プランに商品が設定されている場合、商品のデータを使用する
      if (planSelectFormData.sellerProduct) {
        hotelSearchParams.target = planSelectFormData.sellerProduct.hotelSettings.target;
        hotelSearchParams.code = planSelectFormData.sellerProduct.hotelSettings.hotelCode;
      }

      setHotelSearchParams((prevState) => ({
          ...prevState,
        areaCode: hotelAreaCode,
        target: hotelSearchParams.target,
        code: hotelSearchParams.code,
        search_sort: hotelSearchParams.sort,
        search_keyword_type: hotelSearchParams.searchKeyWordType,
        search_keyword_mode: hotelSearchParams.searchKeyWordMode,
        search_keyword_code: hotelSearchParams.searchKeyWordCode,
        search_hotel_type_code: hotelSearchParams.searchHotelType,
        paged: 1
        }))
      setLoadingHotel(true);
      setActionInitHotel(true);
      setHotelAllData((prevState) => ({
        ...prevState,
        hotelList: []
      }))
      hotelController.abort();

    }
  };

  const [hotelController, setHotelController] = useState(new AbortController())
  /**
   * ホテル一覧検索
   */
  const searchHotelList = async (
    searchQuery: hotelSearchQuery,
  ) => {
    setLoadingHotel(true);
    const abortController = new AbortController()
    const hotelResult = await getHotel(abortController, searchQuery);
    
    const updatePlanSelectFormData = planSelectFormData;
    if (hotelResult && hotelResult?.hotelList.length > 0) {
      updatePlanSelectFormData.isHotels = true;
      const firstHotelIndex = hotelResult.hotelList.findIndex((hotelList) => {
        return hotelList.sold_out_flg !== 1;
      })
      updatePlanSelectFormData.hotel = [
        {
          useDate: searchParams.checkin,
          detail: hotelResult.hotelList[firstHotelIndex],
          checkIn: searchParams.checkin,
          checkOut: searchParams.checkout,
          area: hotelResult.hotelList[firstHotelIndex]?.sub_area_code ?? "",
          total: hotelResult.hotelList[firstHotelIndex]?.min_price ?? 0,
        },
      ];
      updatePlanSelectFormData.hotelData = hotelResult.hotelList;
      setPlanSelectFormData((prevState) => ({
        ...prevState,
        isHotels: updatePlanSelectFormData.isHotels,
        hotelData: updatePlanSelectFormData.hotelData,
        hotel: updatePlanSelectFormData.hotel,
        hotelTotal: updatePlanSelectFormData.hotel[0]?.detail?.min_price ?? 0,
      }));
      
      setHotelAllData({...hotelResult});
      setIsNotFound(false);
    } else {
      setIsNotFound(true);
      return;
    }
  };
  
  const updateSelectedHotel = (selectedHotel: selectHotelDetail) => {
    const updateHotel: PlanSelectFromHotelType[] = [
      {
        useDate: searchParams.checkin,
        detail: selectedHotel,
        checkIn: searchParams.checkin,
        checkOut: searchParams.checkout,
        area: selectedHotel?.sub_area_code ?? "",
        total: selectedHotel?.min_price ?? 0,
      },
    ];
    setPlanSelectFormData((prevState) => ({
      ...prevState,
      hotel: updateHotel,
      hotelTotal: updateHotel[0]?.detail?.min_price ?? 0,
    }));
  }
  
  useEffect(() => {
    if (actionInitHotel) {
      if (hotelAllData.hotelList.length > 0) {
        setHotelAllData((prevState) => ({
          ...prevState,
          hotelList: [],
        }));
      } else {
        setActionInitHotel(false);
        // ホテル一覧検索
        searchHotelList(updateHotelSearchParams);
      }
    } else if (hotelAllData.hotelList.length > 0 && hotelAllData.currentPage !== hotelAllData.totalPage) {
      addHotelList();
    } else {
      setIsListLoading(false);
    }
  }, [hotelAllData])

  const abortController = new AbortController()
  const addHotelList = () => {
    (async () => {
      if ( hotelAllData.totalPage > 1) {
        setIsListLoading(true)
        if (sellerInfo !== undefined && searchParams !== undefined) {
          const paged = hotelAllData.currentPage + 1;
          const hotelAreaCode = getHotelAreaCode(planSelectFormData);
          let target = sellerInfo.hotelSettings.target;
          let code = sellerInfo.hotelSettings.hotelCode;

          // 基本プランに商品が設定されている場合、商品のデータを使用する
          if (planSelectFormData.sellerProduct) {
            target = planSelectFormData.sellerProduct.hotelSettings.target;
            code = planSelectFormData.sellerProduct.hotelSettings.hotelCode;
          }

          setHotelController(abortController);
          updateHotelSearchParams.area_code = hotelAreaCode;
          updateHotelSearchParams.target = target;
          updateHotelSearchParams.code = code;
          updateHotelSearchParams.query = searchParams;
          updateHotelSearchParams.paged = paged;
          // ホテル一覧検索
          const hotelResult = await getHotel(
            abortController,
            updateHotelSearchParams);
          if (hotelResult && hotelResult?.hotelList.length > 0 && hotelAllData?.hotelList){
            const updateHotelList = [...hotelAllData?.hotelList].concat(hotelResult?.hotelList);
            setHotelAllData((prevState) => ({
              ...prevState,
              hotelList: updateHotelList,
              currentPage: hotelResult.currentPage
            }))
            setPlanSelectFormData((prevState) => ({
              ...prevState,
              hotelData: updateHotelList,
            }));
          }
          // scrollToHotelSection(); // `宿泊の選択`の場所にスクロールする
        }
        
        setIsListLoading(false);
      }
    })();
  }

  return (
    <MainTemplate title={getHeadTitle(planSelectFormData, undefined)} fixedBottom={true} planSelectFormData={planSelectFormData}>
      <div className="bgc-white border-bottom-gray pb-16 pb-24-md">
        <div className="wrap-content-900-no_padding">
          <PartsStep activeStep={1}></PartsStep>
        </div>

        {/* 操作ガイドボタン */}
        <PartsTutorialButton value="component-plan_select" />

        <FreeSpaceSection freeSpaceContent={freeSpaceContent}></FreeSpaceSection>

        <div className="wrap-content-900">
          {/* <!--アラート--> */}
          <div className="box-alert-red-small icon-alert d-n">
            <p className="fz-16">選択した内容、旅行代金をご確認ください</p>
          </div>

          {/* 選択中の検索条件 */}
          <p className="fz-12 fz-13-md fw-b mt-0 mt-20-md mb-4">選択中の検索条件</p>
          <div className="box-light-gray xsmall">
            <div className="search-result">
              <dl className="search-result-item">
                <dt className="search-result-item-dt">利用空港</dt>
                <dd className="search-result-item-dd">
                  <ul className="search-result-item-dd-flight">
                    <li key={"search_from"} className="search-result-item-dd-flight-item">
                      {getFlightCodeLabel(searchParams?.from)}
                    </li>
                    <li key={"search_to"} className="search-result-item-dd-flight-item">
                      {getFlightCodeLabel(searchParams?.to)}
                    </li>
                  </ul>
                </dd>
              </dl>

              <dl className="search-result-item">
                <dt className="search-result-item-dt">旅行期間</dt>
                <dd className="search-result-item-dd">
                  <ul className="search-result-item-dd-date">
                    <li key={"search_start"} className="search-result-item-dd-date-item">
                      {dateFormat(searchParams?.start, "YYYY/MM/DD")}
                    </li>
                    <li key={"search_end"} className="search-result-item-dd-date-item">
                      {dateFormat(searchParams?.end, "YYYY/MM/DD")}
                    </li>
                  </ul>
                </dd>
              </dl>

              <dl className="search-result-item">
                <dt className="search-result-item-dt">部屋数・人数</dt>
                <dd className="search-result-item-dd">
                  {getHotelRoomPeopleLabel(searchParams?.roomDetail, searchParams?.rooms)}
                  {/* 1室・4名（大人1/子供3） */}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>

      {/* 検索ボックス */}
      <TopSearchbox query={searchParams} callbackSearch={handleSearch}></TopSearchbox>

      {is500Error && (
        <>
          <Error500></Error500>
        </>
      )}

      {isNotFound && (
        <>
          <SearchNotFound></SearchNotFound>
        </>
      )}

      {!is500Error && !isNotFound && (
        isPlan ? ( // プランあり
          <>
            {searchLoading ? ( // ローディング中の表示
              <div className="mt-32 mt-40-md">
                <div className="wrap-content-900">
                  <LoadingOverlay active={searchLoading}>
                    <div className="pb-100 pt-80"></div>
                  </LoadingOverlay>
                </div>
              </div>
            )
            : activityStockExist ? ( // 選択可能なプランあり
              <>
                {planType !== "option" && (
                  <>
                    {/* 基本プラン */}
                    <SectionPlan
                      activityStockExist={activityStockExist}
                      planSelectFormData={planSelectFormData}
                      activities={activities}
                      searchParams={searchParams}
                      isLoading={isLoadingComprehenshive}
                      setActivities={setActivityCallBack}
                    ></SectionPlan>
                  </>
                )}

                {/* フライト選択 */}
                {<SectionFlight
                  planSelectFormData={planSelectFormData}
                  flights={flightEntities}
                  currentFlightEntities={currentFlightEntities}
                  changeFlightCallback={handleFlightSelectCallback}
                  changeAirportCallback={changeAirportCallback}
                  search={searchParams}
                  isLoading={isLoadingFlight}
                  canSelect={canSelect}
                  anaHasNoFlight={anaHasNoFlight}
                  jalHasNoFlight={jalHasNoFlight}
                  profitRate={planSelectFormData?.planData?.sellerIndo.profitRate?? "0"}
                ></SectionFlight>}

                {/* ホテル選択 */}
                <SectionStay 
                  planSelectFormData={planSelectFormData}
                  selectPlan={handleMove}
                  isLoading={isLoadingHotel}
                  isListLoading={isListLoading}
                  canSelect={canSelect}
                  callback={()=> {}}
                  sort={hotelSort}
                  hotelAllData={hotelAllData}
                  hotelController={hotelController}
                  handleHotelSelected={updateSelectedHotel}
                ></SectionStay>
              </>
            )
            : (<SearchNotFound></SearchNotFound>) // 選択可能なプランなし
            }
            <div className="parts-bottom-fixed">
              <div className="wrap-content-900-no_padding ">
                <div className="box-white fixed-padding">
                  <div className="d-f ai-c jc-sb jc-fe-md">
                    <p className="fw-b pr-24">
                      現在の旅行代金合計<br />
                      <span className="fz-12 fw-n">（※申込人数分の総額）</span>
                    </p>
                    <p className="fz-24 fz-28-md c-red fw-b">
                      {planSelectFormData.basicPriceTotal > 0 ? planSelectFormData.basicPriceTotal.toLocaleString() : "--"}
                      <span className="fz-14 fz-16-md">円(税込)</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
        : (<ServiceStop></ServiceStop>) // プランなし
      )}
    </MainTemplate>
  );
};

export default PlanSelectPage;
