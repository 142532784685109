import {priceResponce, priceResponseCarRentalDetail, priceResponseCarRentalOptions} from "../../types/price";


/**
 * 1台あたりのレンタカー料金を計算する（オプションをひく）
 * @param priceCarRentalDetail
 * @param priceCarRentalOptions
 */
export const calcCarRentalPrice = (priceCarRentalDetail: priceResponseCarRentalDetail, priceCarRentalOptions: priceResponseCarRentalOptions) => {
  let totalPrice = priceCarRentalDetail.price;
  
  // 安心パック/ベーシック
  if (priceCarRentalOptions.anshin_price && priceCarRentalOptions.anshin_price > 0) {
    totalPrice -= priceCarRentalOptions.anshin_price;
  }
  
  // 安心パック/プレミアム
  if (priceCarRentalOptions.anshinpremium_price && priceCarRentalOptions.anshinpremium_price > 0) {
    totalPrice -= priceCarRentalOptions.anshinpremium_price;
  }
  
  // 乳幼児ベビーシート
  if (priceCarRentalOptions.baby_seat && priceCarRentalOptions.baby_seat > 0) {
    totalPrice -= priceCarRentalOptions.baby_seat_price * priceCarRentalOptions.baby_seat;
  }
  
  // 幼児用チャイルドシート
  if (priceCarRentalOptions.child_seat && priceCarRentalOptions.child_seat > 0) {
    totalPrice -= priceCarRentalOptions.child_seat_price * priceCarRentalOptions.child_seat;
  }
  
  // ジュニアシート
  if (priceCarRentalOptions.junior_seat && priceCarRentalOptions.junior_seat > 0) {
    totalPrice -= priceCarRentalOptions.junior_seat_price * priceCarRentalOptions.junior_seat;
  }
  
  return totalPrice;
}
