import React from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { InputsForm5 } from "../../../types/InputsForm5";

//scss
import "../../../scss/common.scss";
import "../../../scss/component.scss";
import { InputCheckbox } from "../../input/InputCheckbox";
import { InputRadio } from "../../input/InputRadio";


type Props = {
  control: any;
  errors: FieldErrors;
  register: UseFormRegister<InputsForm5>;
}

const SectionOther: React.FC<Props> = (props) => {
  return (
    <>
      <section>
        {/* その他確認事項について */}
          <h2 className="heading-2">その他確認事項について</h2>
          <div className="card mb-20 mb-32-md">
            <h3 className="heading-3">
              特別な配慮を必要とする方
              <span className="label-required ml-5">必須</span>
            </h3>
            <p className="mb-20">
              ご参加者の中に、健康を害している方、車椅子などの器具をご利用になっている方や心身に障がいのある方、食物アレルギー・動物アレルギーのある方、妊娠中の方、妊娠の可能性がある方、身体障害者補助犬（盲導犬、聴導犬、介助犬）をお連れの方その他特別の配慮を必要とする方がいる。
            </p>
            <div className="form-content-input">
              <div
                className={
                  "form-content-input-sexual" +
                  (props.errors.other?.need_consideration ? " error" : "")
                }
              >
                <InputRadio
                  name="other.need_consideration"
                  control={props.control}
                  radios={[
                    { label: "いる", value: "yes" },
                    { label: "いない", value: "no" },
                  ]}
                  options={{ required: true }}
                  isRow={true}
                ></InputRadio>
                {props.errors.other?.need_consideration && (
                  <p className="form-error_text">
                    特別な配慮を必要されるかをご選択ください。
                  </p>
                )}
              </div>
            </div>
          </div>
        
          <div className="card mb-20 mb-32-md">
            <h3 className="heading-3">
              添乗員の有無
              <span className="label-required ml-5">必須</span>
            </h3>
            <div className="form-content-input">
              <div
                className={
                  "form-content-input-sexual" +
                  (props.errors.other?.tourist ? " error" : "")
                }
              >
                <InputRadio
                  name="other.tourist"
                  control={props.control}
                  radios={[
                    { label: "同行いたします", value: "yes" },
                    { label: "同行いたしません", value: "no" },
                  ]}
                  options={{ required: true }}
                  isRow={true}
                ></InputRadio>
                {props.errors.other?.tourist && (
                  <p className="form-error_text">
                    添乗員を必要されるかをご選択ください。
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="card mb-20">
            <p className="mb-20">
              <a
                href="/Conditions"
                className="button-large-orange block"
                target="_blank"
                rel="noreferrer"
              >
                旅行条件書を表示する
                <i className="icon-blank-white ml-5"></i>
              </a>
            </p>
            <div className="form-input-checkbox">
              <InputCheckbox
                name="other.agree"
                register={props.register}
                checkboxes={[
                  {
                    id: "other.agree",
                    label: "",
                    value: "yes"
                  },
                ]}
                options={{ required: true }}
                className={"form-input-checkbox-icon"}
              ></InputCheckbox>
              <span className="form-input-checkbox-text">
                <label htmlFor="other.agree.0">
                  旅行条件書を表示し、表示された諸条件について同意する
                </label>
              </span>
            </div>
            {props.errors.other?.agree && (
              <p className="form-error_text c-red ta-c mt-4">
                「同意する」にチェックをいれてください。
              </p>
            )}  
          </div>
      </section>
    </>
  );
};

export default SectionOther;