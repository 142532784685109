import React, {useEffect} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel,} from "@material-ui/core";

//datepicker
import DatePicker　, { registerLocale }  from "react-datepicker";
import ja from 'date-fns/locale/ja';
import "react-datepicker/dist/react-datepicker.css"
import {dateFormat} from "../../utils/convert";


//モーダルをインポート
import {SearchCounter,} from "./../modal"

//scss
import "../../scss/common.scss";
import "../../scss/component.scss";

import {PlanSearch, roomDetail} from "../../types/Search";

import dayjs from "dayjs";
import {airCode, airportGroup} from "../../config/Airport";
import {getHotelSearchRoomPeopleLabel} from "../../utils/hotel";
import {dayAgo, diffDate, nextDate, rageDiffDate, rangeDate} from "../../utils/convert";

import {
  getMultiAirports,
  getFlightCodeLabel,
  getArrivalAirports
} from "../../utils/flight";
import {checkBabyNums, getSearchTravelerNums} from "../../utils/search";
import _ from "lodash";

type Props = {
  query: PlanSearch
  callbackSearch: ( data: PlanSearch ) => void
}

const TopSearchbox: React.FC<Props> = ({
  query,
  callbackSearch
}) => {

  const minDate = dayjs().format('YYYY-MM-DD');

  // モーダルのステータスを追加する
  const [searchCounter, setSearchCounter] = React.useState(false);
  const [searchParams, setSearchParams] = React.useState<PlanSearch>();

  // セレクトボックスの空港の配列
  const [departures, setDepartures] = React.useState<string[]>(); // 出発空港
  const [arrivals, setArrivals] = React.useState<string[]>();     // 到着空港

  // 利用空港
  const [from, setFrom] = React.useState(query?.from);
  const [to, setTo] = React.useState(query?.to);
  const [c_from, setC_From] = React.useState(query?.c_airport_from);
  const [c_to, setC_To] = React.useState(query?.c_airport_to);

  
  // 旅行期間
  const [start, setStart] = React.useState(query?.start);
  const [end, setEnd] = React.useState(query?.end);

  // チェックインアウト
  const [checkin, setCheckin] = React.useState(query?.checkin);
  const [checkout, setCheckout] = React.useState(query?.checkout);
  
  const [roomDetail, setRoomDetail] = React.useState(query?.roomDetail);

  useEffect(() => {
    setRoomDetail([...query.roomDetail]);
  }, [query])

  // モーダルのhandleを追加する
  const handleSearchCounter = () => {
    setSearchCounter(!searchCounter)
  }


  // カウンター
  const [count, setCount] = React.useState(1);
  const disabled = count <= 1;
  const plusDisabled = count >= 9;

  // 帰りの空港を変更する
  const [checked, setChecked] = React.useState(query?.isChange);

  const handleCheckboxChangeAirport = (event: React.ChangeEvent<{}>) => {
    const check = (event.target as HTMLInputElement).checked
    setChecked(check);
    if(searchParams) {
      searchParams.isChange = check
      const arrivalAirports = getArrivalAirports(searchParams.from, true, false);
      if (searchParams.isChange && (searchParams.c_airport_to === "" || (searchParams.c_airport_to !== "" && !arrivalAirports.includes(searchParams.c_airport_to)) )) {
        searchParams.c_airport_to = searchParams.from;
      }
      setSearchParams(searchParams)
    }
  }

  const handleSearch = () => {
    if(searchParams) {
      callbackSearch(searchParams)
    }

  }

  // 利用空港
  const handleChangeFrom = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFrom(e.target.value);
    if(checked && searchParams) {
      searchParams.c_airport_to = e.target.value;
      setC_To(e.target.value);
    }
    if(searchParams) {
      searchParams.from = e.target.value;
    }
    setSearchParams(searchParams);
    setArrivals(getArrivalAirports(e.target.value, true, false));
    setDepartures(getMultiAirports(e.target.value, true, true));
  }
  const handleChangeTo = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTo(e.target.value);
    if(searchParams) {
      searchParams.to = e.target.value;
    }
    setSearchParams(searchParams);
  }
  const handleChangeC_From = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setC_From(e.target.value);
    if(searchParams) {
      searchParams.c_airport_from = e.target.value;
    }
    setSearchParams(searchParams);
  }
  const handleChangeC_To = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setC_To(e.target.value);
    if(searchParams) {
      searchParams.c_airport_to = e.target.value;
    }
    setSearchParams(searchParams);
  }

  // 旅行期間
  const maxRange = 30;
  const handleChangeTravelDateStart = (newValue: Date | null) => {
    const _date = dayjs(newValue).format('YYYY-MM-DD');
    setStart(_date);

    if(searchParams) {
      searchParams.start = _date;
      searchParams.checkin = _date;

      const range = rageDiffDate(_date, searchParams.end);
      if (range  <= 0 || range > maxRange) {
        const updateCheckOut = nextDate(_date, searchParams.days, 'YYYY-MM-DD');
        const nextDay = nextDate(_date, 1, 'YYYY-MM-DD');
        if (rageDiffDate(_date, checkout) <= 0 && rageDiffDate(searchParams.end, updateCheckOut) <= 0) {
          searchParams.end = nextDay;
          searchParams.checkout = nextDay;
          setEnd(nextDay)
          setCheckout(nextDay);
        } else {
          searchParams.end = updateCheckOut;
          searchParams.checkout = updateCheckOut;
          setEnd(updateCheckOut)
          setCheckout(updateCheckOut);
        }
      }
      setCheckin(_date)
      setSearchParams({...searchParams});
    }
  }

  const handleChangeTravelDateEnd = (newValue: Date | null) => {
    let _date = dayjs(newValue).format('YYYY-MM-DD');
    if(searchParams) {
      const range = rageDiffDate(searchParams.start, _date);
      if (range > maxRange) {
        _date = nextDate(searchParams.start, maxRange, 'YYYY-MM-DD');
      }
      setEnd(_date);
      searchParams.end = _date;
      searchParams.checkout = _date;
      setCheckout(_date)
      setSearchParams({...searchParams});
    }
  }

  // チェックイン
  const handleCheckin = (newValue: Date | null) => {
    const _date = dayjs(newValue).format('YYYY-MM-DD');
    if (_date) {
      setCheckin(_date);
      if(searchParams) {
        searchParams.checkin = _date;
        setSearchParams(searchParams);
      }
    }

  }
  const handleCheckout = (newValue: Date | null) => {
    const _date = dayjs(newValue).format('YYYY-MM-DD');
    if (_date) {
      setCheckout(_date);
      if(searchParams) {
        searchParams.checkout = _date;
        setSearchParams(searchParams);
      }
    }

  }
  // 部屋数、人数

    const handleUpdateSearchRoomDetail = (roomData: roomDetail[]|undefined) => {
    if(roomData !== undefined && searchParams !== undefined) {
      const totalTraveler = getSearchTravelerNums(roomData);
      if (totalTraveler > 9) {
        alert("予約可能な人数の上限を超えています。９名以下でお申し込みください。");
      } else if (checkBabyNums(roomData)) {
        alert("3歳未満の人数が大人の人数を超えている場合は予約できません。");
      } else {
        searchParams["roomDetail"] = [...roomData];
        searchParams["rooms"] = roomData.length;
        setRoomDetail([...roomData]);
        setSearchParams(searchParams)
        setCount(roomData.length);
        setSearchCounter(!searchCounter)
      }
    } else {
      setSearchCounter(!searchCounter)
    }
  }

  useEffect(() => {
    if(query) {
      const cAirportFrom = query.c_airport_from ?? query.to;
      setSearchParams({...query
      })
      setFrom(query.from);
      setTo(query.to);
      setStart(query.start);
      setEnd(query.end);
      setCheckin(query.checkin);
      setCheckout(query.checkout);
      setRoomDetail(query.roomDetail);
      setC_From(cAirportFrom);
      setC_To(query.c_airport_to);
      setCount(query.rooms)
      setChecked(query.isChange);

      setDepartures(getMultiAirports(query.from, true, true));
      setArrivals(getArrivalAirports(query.from, true, false));
    }
  },[query]);

  //旅行期間：帰着日
  const TravelDateEnd = new Date();
  const [endDate, setEndDate] = React.useState(TravelDateEnd);
  registerLocale('ja', ja);

  //readOnly属性を付与するとdate-pickerが表示されなくなるので対策
  const ForceReadOnlyInput = React.forwardRef((props, ref) => (
    <input {...props} readOnly={true} />
  ));

  
  const updateRoomCount = (roomCount: number|string) => {
    const newRoomCount = Number(roomCount);
    if (searchParams?.rooms && searchParams?.roomDetail) {
      const totalTraveler = getSearchTravelerNums(searchParams.roomDetail);
      const newRoomDetail = _.cloneDeep(roomDetail);

      if (searchParams.rooms < newRoomCount && totalTraveler < 9) {
        const addAdult = 1;
        // 部屋数を増やした(最後に追加)
        newRoomDetail.push({
          adult: addAdult,
          child: [],
        })
        searchParams.rooms = newRoomCount; // 部屋数
        setCount(newRoomCount);
      } else if (newRoomCount < searchParams.rooms) {
        // 部屋数を減らした(最後の項目を削除)
        newRoomDetail.pop();
        searchParams.rooms = newRoomCount; // 部屋数
        setCount(newRoomCount);
      }
      searchParams.roomDetail = newRoomDetail; // 人数詳細
      setRoomDetail([...newRoomDetail]);
      setSearchParams({...searchParams});
    }
  }

  return (
    <>
        {/* <!--検索ボックス--> */}
        <div className="searchbox-wrap">
          <div>
            <Accordion className="accordion-search">
              <AccordionSummary className="accordion-search-heading">
                <i className="icon-search-white mr-10 ml-32"></i>検索条件を変更する
                <span className="icon-accodion-toggle-wrap">
                  <span className="icon-accodion-toggle"></span>
                </span>
              </AccordionSummary>
              <AccordionDetails>
                <div className="accordion-search-content">
                  { searchParams ? (
                  <div className="searchbox-content">
                    <div className="searchbox-content-top">
                      {/* 利用空港 */}
                      <div className="searchbox-content-item column-50per pr-24-md">
                        <div className="searchbox-content-item-group">
                          <p className="fz-16 fw-b mb-10">利用空港</p>
                          <div className="searchbox-content-item-group-block">
                            <div className="searchbox-content-item-group-block-child">
                              <div className="searchbox-content-item-group-block-child-item">
                                <p className="mb-8 fz-12">出発空港</p>
                                <div className="select_box-default">
                                  <select name="prefecture" value={from}
                                    onChange={(e) => handleChangeFrom(e)}
                                  >
                                    {
                                      airportGroup && airportGroup.map((data: any, index: number) => (
                                        <optgroup label={data.groupName} key={index}>
                                          {Object.keys(data.airportList).map((aircode: any, i: number) => (
                                            <option value={aircode} key={i}>{data.airportList[aircode]}</option>
                                          ))}
                                        </optgroup>
                                      ))
                                    }
                                  </select>
                                </div>
                              </div>

                              <div className="searchbox-content-item-group-block-child-item">
                                <p className="mb-8 fz-12">到着空港</p>

                                <div className="select_box-default">
                                  <select name="prefecture" value={to}
                                          onChange={(e) => handleChangeTo(e)}
                                  >
                                    {
                                      airportGroup && airportGroup.map((data: any, index: number) => (
                                        <optgroup label={data.groupName} key={index}>
                                          {Object.keys(data.airportList).map((aircode: any, i: number) => (
                                            <option value={aircode} key={i}>{data.airportList[aircode]}</option>
                                          ))}
                                        </optgroup>
                                      ))
                                    }
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="searchbox-content-item-group-block">
                            <div className="searchbox-content-item-group-block-child">
                              <div className="form-input-checkbox">
                                <FormControlLabel
                                  label="帰りの空港を変更する"
                                  control={
                                    <Checkbox
                                      checked={checked}
                                      name="checkbox"
                                      onChange={(e) => handleCheckboxChangeAirport(e)}
                                      value="somevalue"
                                      className="fz-12 fz-14-md"
                                    />
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          {/* 利用空港；帰りの空港を変更（チェック時のみ表示） */}
                          <div
                            className={
                              "searchbox-content-item-group-block mt-8" +
                              (!checked ? " d-n" : "")
                            }
                          >
                            <div className="searchbox-content-item-group-block-child">
                              <div className="searchbox-content-item-group-block-child-item">
                                <p className="mb-8 fz-12">出発空港</p>
                                <div className="select_box-default">
                                  <select name="c_arrive"
                                          value={c_from}
                                          onChange={(e) => handleChangeC_From(e)}
                                  >
                                    {
                                      airportGroup && airportGroup.map((data: any, index: number) => (
                                        <optgroup label={data.groupName} key={"c_from_" + index}>
                                          {Object.keys(data.airportList).map((aircode: any, i: number) => (
                                            <option value={aircode} key={i}>{data.airportList[aircode]}</option>
                                          ))}
                                        </optgroup>
                                      ))
                                    }
                                  </select>
                                </div>
                              </div>

                              <div className="searchbox-content-item-group-block-child-item">
                                <p className="mb-8 fz-12">到着空港</p>
                                <div className="select_box-default">
                                  <select name="c_arrive"
                                          value={c_to}
                                          onChange={(e) => handleChangeC_To(e)}
                                  >
                                    {
                                      arrivals ? arrivals.map((airCode, index) => (
                                        <option key={"c_airport" + index} value={airCode}>
                                          {getFlightCodeLabel(airCode)}
                                        </option>
                                      )) : ""
                                    }
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* 旅行期間 */}
                      <div className="searchbox-content-item border-bottom column-50per">
                        <div className="searchbox-content-item-group">
                          <p className="fz-16 fw-b mb-10">旅行期間<span className="fz-12 fw-n">（最長30日）</span></p>
                          <div className="searchbox-content-item-group-block">
                            <div className="searchbox-content-item-group-block-child">
                              <div className="searchbox-content-item-group-block-child-item">
                                <p className="mb-8 fz-12">出発日</p>
                                <div className="form-input-date-icon-wrap">
                                  <DatePicker
                                    dateFormat="yyyy/MM/dd"
                                    locale='ja'
                                    selected={new Date(start)}
                                    value={dateFormat(start, 'YYYY/MM/DD')}
                                    className="form-input-date datepicker fw-n"
                                    onChange={handleChangeTravelDateStart}
                                    minDate={new Date(nextDate(minDate, 5, 'YYYY-MM-DD'))}
                                    customInput={<ForceReadOnlyInput />}
                                  />
                                </div>
                              </div>

                              <div className="searchbox-content-item-group-block-child-item">
                                <p className="mb-8 fz-12">帰着日</p>
                                <div>

                                  <div className="form-input-date-icon-wrap">
                                    <DatePicker
                                        dateFormat="yyyy/MM/dd"
                                        locale='ja'
                                        selected={new Date(end)}
                                        value={dateFormat(end, 'YYYY/MM/DD')}
                                        className="form-input-date datepicker fw-n"
                                        onChange={handleChangeTravelDateEnd}
                                        minDate={new Date(nextDate(start, 1, 'YYYY-MM-DD'))}
                                        maxDate={new Date(nextDate(start, maxRange, 'YYYY-MM-DD'))}
                                        customInput={<ForceReadOnlyInput />}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="searchbox-content-bottom">
                      {/* 宿泊条件 */}
                      <div className="searchbox-content-item border-bottom-none column-50per pr-24-md">
                        <div className="searchbox-content-item-group">
                          <p className="fz-16 fw-b mb-10">宿泊条件<span className="fz-12 fw-n">（希望宿泊日を1泊以上指定）</span></p>
                          <div className="searchbox-content-item-group-block">
                            <div className="searchbox-content-item-group-block-child">
                              <div className="searchbox-content-item-group-block-child-item">
                                <p className="mb-8 fz-12">チェックイン</p>
                                <div className="form-input-date-icon-wrap">
                                  <DatePicker
                                    dateFormat="yyyy/MM/dd"
                                    locale='ja'
                                    selected={new Date(checkin)}
                                    value={dateFormat(checkin, 'YYYY/MM/DD')}
                                    className="form-input-date datepicker fw-n"
                                    onChange={handleCheckin}
                                    minDate={new Date(searchParams.start)}
                                    maxDate={new Date(dayAgo(searchParams.end, 1, 'YYYY-MM-DD'))}
                                    customInput={<ForceReadOnlyInput />}
                                  />
                                </div>
                              </div>

                              <div className="searchbox-content-item-group-block-child-item">
                                <p className="mb-8 fz-12">チェックアウト</p>
                                <div className="form-input-date-icon-wrap">
                                  <DatePicker
                                    dateFormat="yyyy/MM/dd"
                                    locale='ja'
                                    selected={new Date(checkout)}
                                    value={dateFormat(checkout, 'YYYY/MM/DD')}
                                    className="form-input-date datepicker fw-n"
                                    onChange={handleCheckout}
                                    minDate={new Date(nextDate(searchParams.start, 1, 'YYYY-MM-DD'))}
                                    maxDate={new Date(searchParams.end)}
                                    customInput={<ForceReadOnlyInput />}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* 部屋数 ＋ 人数 */}
                      <div className="searchbox-content-item column-50per">
                        <div className="searchbox-content-item-group">
                          <p className="d-n d-b-md fz-16 fw-b mb-10">　</p>
                          <div className="searchbox-content-item-group-block">
                            <div className="searchbox-content-item-group-block-child">
                              <div className="searchbox-content-item-group-block-child-item clumn-30per">
                                <p className="mb-8 fz-12 pr-32-md">部屋数</p>
                                {/* カウンター */}
                                <div className="counter pr-32-md">
                                  <div className="counter-minus">
                                    <button
                                      type="button"
                                      className="counter-minus-icon"
                                      onClick={() => updateRoomCount(count - 1)}
                                      disabled={disabled}
                                    ></button>
                                  </div>
                                  <div className="counter-value">{count}</div>
                                  <div className="counter-plus">
                                    <button
                                      className="counter-plus-icon"
                                      onClick={() => updateRoomCount(count + 1)}
                                      disabled={plusDisabled}
                                    ></button>
                                  </div>
                                </div>
                              </div>

                              <div className="searchbox-content-item-group-block-child-item clumn-70per">
                                <p className="mb-8 fz-12">人数</p>
                                <div className="select_box-default">
                                  <button
                                    className="select_box-default-button"
                                    onClick={handleSearchCounter}
                                  >
                                    {getHotelSearchRoomPeopleLabel(searchParams?.roomDetail)}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  ): ""
                  }

                  <div className="accordion-search-button">
                    <button 
                      className="button-large-red w-100per"
                      onClick={handleSearch}
                    >
                      <i className="icon-search_orange-circle mr-10 fz-24 fz-26-md"></i>
                      検索する
                    </button>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>

        {/* モーダル：人数設定 */}
      <SearchCounter isShow={searchCounter} roomDetail={roomDetail} count={count} callback={handleSearchCounter} updateRoomDetail={handleUpdateSearchRoomDetail} />
    </>
  );
};

export default TopSearchbox;
