import React, { Fragment } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";

//scss
import "../../scss/common.scss";
import "../../scss/component.scss";
import {priceResponce} from "../../types/price";
import {dateFormat, toCircled} from "../../utils/convert";
import { okinawaSaihakken } from "../../config/price";
import SectionTravelDiscountPrice from "./OkinawaSaihakken/SectionTravelDiscountPrice";
import SectionCouponsDiscountPrice from "./Coupons/SectionTravelDiscountPrice";
import {getCarRentalAnshinOptionItem} from "../../utils/carRental";
import {calcCarRentalPrice} from "../../utils/carRental/getReservePrices";

type Props = {
  priceData: priceResponce | undefined,
  cardNumber?: number | undefined,
  cardName?: string
}

const SectionTravelPrice: React.FC<Props> = (props) => {
  const priceData = props.priceData?.result;
  return (
    <>
      {priceData && (
        <>
          <section>
            {/* 旅行代金 */}
            <h2 className="heading-2">旅行代金</h2>
            <div className="box-total">
              <span className="box-total-heading">お支払い総額</span>
              <span className="box-total-pay">
                {priceData?.taxTotalPrice?.toLocaleString()}
                    <span className="c-red fz-16">円(税込)</span>
              </span>
              {props.cardNumber && props.cardName && (
                <>
                  <span className="box-total-credit">
                    <span className="box-total-credit-shield">
                      ＊＊＊＊-＊＊＊＊-＊＊＊＊-
                    </span>
                    {props.cardNumber.toString().slice(-4)}：{props.cardName}
                  </span>
                </>
              )}
            </div>
            <Accordion className="accordion-total">
              <AccordionSummary
                aria-controls="panel1a-content"
                className="accordion-total-heading"
                id="panel1a-header"
              >
                旅行代金内訳を見る
                <span className="icon-accodion"></span>
              </AccordionSummary>
              <AccordionDetails>
                <div className="accordion-total-content">
                  <div className="box-separate">
                    <div className="box-separate-item">
                      {priceData?.basicPriceDetail.map((basicPriceDetail, bIndex) => (
                          <Fragment key={bIndex}>
                            <div className="box-separate-item-block large">
                              {bIndex == 0 ?
                                /* 最初の要素のみ見出しを出力 */
                                <>
                                  <h3 className="c-red fz-16 fw-b mb-16">
                                    基本旅行代金
                                  </h3>
                                </>:<></>
                              }
                              <p className="fz-14 fz-16-md fw-b mb-8 mb-16-md">客室{toCircled(bIndex + 1)}</p>
                              <div className="box-separate-item-block-subtotal_list">
                                <div className="box-separate-item-block-subtotal_list-item">
                                  <p className="box-separate-item-block-subtotal_list-item-label">
                                    大人
                                  </p>
                                  <p className="box-separate-item-block-subtotal_list-item-subtotal">
                                    {basicPriceDetail.adult.price.toLocaleString()}円<span className="ml-5">×</span>{basicPriceDetail.adult.num}
                                  </p>
                                  <p className="box-separate-item-block-subtotal_list-total-total">
                                    <span className="fz-16 fz-18-md fz-20-md c-red">{(basicPriceDetail.adult.price * basicPriceDetail.adult.num).toLocaleString()}</span>
                                    <span className="fz-14 c-red">円</span>
                                  </p>
                                </div>
                                {basicPriceDetail.child?.map((child, bcIndex) => (
                                  <Fragment key={bcIndex}>
                                    <div className="box-separate-item-block-subtotal_list-item">
                                      <p className="box-separate-item-block-subtotal_list-item-label">
                                        子供 {child.age}歳 <br className="d-n-md" />
                                        {Number(child.age) <= 5 && !child.meal && !child.futon ?
                                          <>
                                            (食事・布団なし)
                                          </>
                                        :
                                          <>
                                            (食事・布団あり)
                                          </>
                                        }
                                      </p>
                                      <p className="box-separate-item-block-subtotal_list-item-subtotal">
                                        {child.price.toLocaleString()}円<span className="ml-5">×</span>{child.num}
                                      </p>
                                      <p className="box-separate-item-block-subtotal_list-total-total">
                                        <span className="fz-16 fz-18-md fz-20-md c-red">{(child.price * child.num).toLocaleString()}</span>
                                        <span className="fz-14 c-red">円</span>
                                      </p>
                                    </div>
                                  </Fragment>
                                ))}
                              </div>
                            </div>
                          </Fragment>
                      ))}
                    </div>

                    {priceData?.carRental && priceData?.carRental.length ?
                      <>
                        <div className="box-separate-item">
                          {priceData?.carRental.map((carRental,cIndex) => (
                            <Fragment key={cIndex}>
                              <div className="box-separate-item-block large">
                                {cIndex === 0 && (
                                  <h3 className="c-red fz-16 fw-b mb-16">レンタカー</h3>
                                )}
                                <p className="fz-14 fz-16-md fw-b">レンタカー{toCircled(cIndex + 1)}</p>
                                <p className="fz-14 fw-b mb-8 mb-16-md">
                                  {carRental.use_date}
                                </p>

                                <div className="box-separate-item-block-subtotal_list">
                                  {carRental.detail.map((carRentalDetail, cdIndex) => (
                                    <Fragment key={cdIndex}>
                                      <div className="box-separate-item-block-subtotal_list-item">
                                        <p className="box-separate-item-block-subtotal_list-item-label">
                                          {carRentalDetail.class_name}（{carRentalDetail.class_model}）
                                        </p>
                                        <p className="box-separate-item-block-subtotal_list-item-subtotal">
                                          {calcCarRentalPrice(carRentalDetail, carRental.options).toLocaleString()}円
                                        </p>
                                        <p className="box-separate-item-block-subtotal_list-total-total">
                                          <span className="fz-16 fz-18-md fz-20-md c-red">{calcCarRentalPrice(carRentalDetail, carRental.options).toLocaleString()}</span>
                                          <span className="fz-14 c-red">円</span>
                                        </p>
                                      </div>
                                    </Fragment>
                                  ))}

                                  {carRental.options.anshin_price > 0 && (
                                    <>
                                      <div className="box-separate-item-block-subtotal_list-item">
                                        <p className="box-separate-item-block-subtotal_list-item-label">
                                          {getCarRentalAnshinOptionItem('安心パック', 'label')}
                                        </p>
                                        <p className="box-separate-item-block-subtotal_list-item-subtotal">
                                          {(carRental.options.anshin_price).toLocaleString()}円
                                        </p>
                                        <p className="box-separate-item-block-subtotal_list-total-total">
                                          <span
                                            className="fz-16 fz-18-md fz-20-md c-red">{(carRental.options.anshin_price).toLocaleString()}</span>
                                          <span className="fz-14 c-red">円</span>
                                        </p>
                                      </div>
                                    </>)}

                                  {carRental.options.anshinpremium_price > 0 && (
                                    <>
                                      <div className="box-separate-item-block-subtotal_list-item">
                                        <p className="box-separate-item-block-subtotal_list-item-label">
                                          {getCarRentalAnshinOptionItem('プレミアム安心パック', 'label')}
                                        </p>
                                        <p className="box-separate-item-block-subtotal_list-item-subtotal">
                                          {(carRental.options.anshinpremium_price).toLocaleString()}円
                                        </p>
                                        <p className="box-separate-item-block-subtotal_list-total-total">
                                          <span
                                            className="fz-16 fz-18-md fz-20-md c-red">{(carRental.options.anshinpremium_price).toLocaleString()}</span>
                                          <span className="fz-14 c-red">円</span>
                                        </p>
                                      </div>
                                    </>)}

                                  {carRental.options.baby_seat > 0 && (
                                    <>
                                      <div className="box-separate-item-block-subtotal_list-item">
                                        <p className="box-separate-item-block-subtotal_list-item-label">
                                          乳幼児ベビーシート
                                        </p>
                                        <p className="box-separate-item-block-subtotal_list-item-subtotal">
                                          {carRental.options.baby_seat_price.toLocaleString()}円<span className="ml-5">×</span>{carRental.options.baby_seat}
                                        </p>
                                        <p className="box-separate-item-block-subtotal_list-total-total">
                                          <span className="fz-16 fz-18-md fz-20-md c-red">{(carRental.options.baby_seat_price * carRental.options.baby_seat).toLocaleString()}</span>
                                          <span className="fz-14 c-red">円</span>
                                        </p>
                                      </div>
                                    </>)}

                                  {carRental.options.child_seat > 0 ?
                                    <>
                                      <div className="box-separate-item-block-subtotal_list-item">
                                        <p className="box-separate-item-block-subtotal_list-item-label">
                                          幼児用チャイルドシート
                                        </p>
                                        <p className="box-separate-item-block-subtotal_list-item-subtotal">
                                          {carRental.options.child_seat_price.toLocaleString()}円<span className="ml-5">×</span>{carRental.options.child_seat}
                                        </p>
                                        <p className="box-separate-item-block-subtotal_list-total-total">
                                          <span className="fz-16 fz-18-md fz-20-md c-red">{(carRental.options.child_seat_price * carRental.options.child_seat).toLocaleString()}</span>
                                          <span className="fz-14 c-red">円</span>
                                        </p>
                                      </div>
                                    </>:<></>
                                  }

                                  {carRental.options.junior_seat > 0 ?
                                    <>
                                      <div className="box-separate-item-block-subtotal_list-item">
                                        <p className="box-separate-item-block-subtotal_list-item-label">
                                          学童用ジュニアシート
                                        </p>
                                        <p className="box-separate-item-block-subtotal_list-item-subtotal">
                                          {carRental.options.junior_seat_price.toLocaleString()}円<span className="ml-5">×</span>{carRental.options.junior_seat}
                                        </p>
                                        <p className="box-separate-item-block-subtotal_list-total-total">
                                          <span className="fz-16 fz-18-md fz-20-md c-red">{(carRental.options.junior_seat_price * carRental.options.junior_seat).toLocaleString()}</span>
                                          <span className="fz-14 c-red">円</span>
                                        </p>
                                      </div>
                                    </>:<></>
                                  }

                                </div>
                              </div>
                            </Fragment>
                          ))}
                        </div>
                      </>:<></>
                    }

                    {priceData?.options && priceData?.options.length ?
                      <>
                        <div className="box-separate-item">
                        {priceData.options.map((option,oIndex) => (
                          <div className="box-separate-item-block large" key={oIndex}>
                            {oIndex == 0 ?
                                /* 最初の要素のみ見出しを出力 */
                                <>
                                  <h3 className="c-red fz-16 fw-b mb-16">
                                  追加オプション
                                  </h3>
                                </>:<></>
                              }
                            
                              <Fragment key={oIndex}>
                                <p className="fz-14 fw-b mb-5">追加オプション{toCircled(oIndex + 1)}</p>
                                <p className="fz-14 fw-b mb-5 mt-0">
                                  ご利用日時：{option.use_date}
                                </p>
                                <p className="fz-13 fz-14-md fw-b mb-20">
                                  {option.plan_name}
                                </p>
                                <div className="box-separate-item-block-subtotal_list">
                                  {option.detail.map((optionDetail, odIndex) => (
                                    <Fragment key={odIndex}>
                                      <div className="box-separate-item-block-subtotal_list-item">
                                        <p className="box-separate-item-block-subtotal_list-item-label">
                                          {optionDetail.name}
                                        </p>
                                        <p className="box-separate-item-block-subtotal_list-item-subtotal">
                                          {optionDetail.price.toLocaleString()}円<span className="ml-5">×</span>{optionDetail.num}
                                        </p>
                                        <p className="box-separate-item-block-subtotal_list-total-total">
                                          <span className="fz-16 fz-18-md fz-20-md c-red">{(optionDetail.price * optionDetail.num).toLocaleString()}</span>
                                          <span className="fz-14 c-red">円</span>
                                        </p>
                                      </div>
                                    </Fragment>
                                  ))}
                                </div>
                              </Fragment>
                            </div>
                          ))}
                        </div>
                      </>:<></>
                    }

                    {priceData?.insurance && (priceData?.insurance.insuranceTotalPrice > 0 || priceData?.insurance.insuranceCancelTotalPrice > 0) ?
                      <>
                        <div className="box-separate-item">
                          {priceData.insurance.insuranceTotalPrice > 0 && (
                            <>
                              <div className="box-separate-item-block large">
                                <h3 className="c-red fz-16 fw-b mb-16">旅行保険</h3>
                                <p className="fz-14 fw-b mb-5 mt-0">
                                  保険期間：{dateFormat(priceData.insurance.DTA.tripStartDate, 'YYYY年MM月DD日(ddd)')} 〜 {dateFormat(priceData.insurance.DTA.tripEndDate, "YYYY年MM月DD日(ddd)")}
                                </p>
                                <div className="box-separate-item-block-subtotal_list">
                                  <div className="box-separate-item-block-subtotal_list-item">
                                    <p className="box-separate-item-block-subtotal_list-item-label">
                                      国内旅行傷害保険
                                    </p>
                                    <p className="box-separate-item-block-subtotal_list-item-subtotal">
                                      {priceData.insurance.individualPrice.toLocaleString()}円<span className="ml-5">×</span>{priceData.insurance.DTA.people.toString()}
                                    </p>
                                    <p className="box-separate-item-block-subtotal_list-total-total">
                                      <span className="fz-16 fz-18-md fz-20-md c-red">{priceData.insurance.insuranceTotalPrice.toLocaleString()}</span>
                                      <span className="fz-14 c-red">円</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          {priceData.insurance.insuranceCancelTotalPrice > 0 && (
                            <>
                              <div className="box-separate-item-block large">
                                <p className="fz-14 fw-b mb-5 mt-0">
                                  保険期間：{dateFormat(priceData.insurance.TripCXL.tripStartDate, "YYYY年MM月DD日(ddd)")} 〜 {dateFormat(priceData.insurance.TripCXL.tripEndDate, "YYYY年MM月DD日(ddd)")}
                                </p>
                                <div className="box-separate-item-block-subtotal_list">
                                  <div className="box-separate-item-block-subtotal_list-item">
                                    <p className="box-separate-item-block-subtotal_list-item-label">
                                      旅のキャンセル保険
                                    </p>
                                    <p className="box-separate-item-block-subtotal_list-item-subtotal">
                                      {priceData.insurance.insuranceCancelTotalPrice.toLocaleString()}円
                                    </p>
                                    <p className="box-separate-item-block-subtotal_list-total-total">
                                      <span className="fz-16 fz-18-md fz-20-md c-red">{priceData.insurance.insuranceCancelTotalPrice.toLocaleString()}</span>
                                      <span className="fz-14 c-red">円</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </>:<></>
                    }
                    <SectionCouponsDiscountPrice
                      isCoupon={priceData?.couponsPrices.length > 0}
                      couponPrice={priceData?.couponDiscountPrice ?? 0}
                      couponName={priceData?.couponsPrices.length > 0 ? priceData?.couponsPrices[0].name : "" }
                    />

                    {/* 全国旅行支援 補助金 */}
                    {okinawaSaihakken && priceData?.discountPrices &&
                      <SectionTravelDiscountPrice discountPrices={priceData.discountPrices}></SectionTravelDiscountPrice>
                    }
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

          </section>
        </>
      )}
    </>
  );
};

export default SectionTravelPrice;
