import {InputsForm1} from "../types/InputsForm1";
import {InputsForm3} from "../types/InputsForm3";
import {InputsForm2} from "../types/InputsForm2";
import {PlanSelectFormData} from "../types/planSelectForm";
import {InputsForm4} from "../types/InputsForm4";
import {geAgeAtDeparture, getAge, getAgeFromBirthday} from "./convert";
import {
  flightReservationPassengers, reserveActivity, reserveCarRentalDetail,
  reserveFlight, reserveFlightData, reserveHotel, reserveInsurance, reserveInsuranceData, reserveRentalCar,
  reserveRepresentative,
  reserveRequestParams, reserveResponce,
  reserveTravelCompanions
} from "../types/reserve";
import {
  convertAirCodeForCarRental,
  convertReserveFlightData, getChildDetail,
  getFlightBirthday,
  getFlightGenderId,
  getFlightPeopleNums
} from "./flight";
import {getHotelGenderCode} from "./hotel";
import {
  getActivityGenderCode,
  getActivityPlanPrice,
  getPlanAreaCode, setOptionsReserveAddFieldList, setPlanReserveAddFieldList,
  setPlanReservePriceTypeDetail
} from "./plan";
import {getCarRentalOfficeAccessType, getCarRentalOptionCode, getCarRentalReservePeople} from "./carRental";
import {creditRequestParams, creditResponce} from "../types/credit";
import {useApi} from "./useApi";
import {getTotalTravelerNums} from "./search";
import {reserveCancelDetailResponce} from "../types/reserveCancel";
import dayjs from "dayjs";
import {perSheetMaxNum} from "../config/carRental";
import {getConnectionFlights, getLastFlightAirCode, getLastFlightAirSubNo, setReserveFlightData} from "./flightV2";
import {insuranceQuoteRequest} from "../types/insurance";

/**
 * 予約時に必要なパラメータ設定
 * @param input
 */
export const getReserveRequestParams = (input: { 
  form1: InputsForm1,
  form2: InputsForm2,
  form3: InputsForm3,
  form4: InputsForm4,
  planSelectFormData: PlanSelectFormData,
  need_consideration:string
  tourist: string
}): reserveRequestParams => {

  /**
   * 代表者
   */
  const applicantBirthDay = input.form1.applicant.birthday_year + '-' + input.form1.applicant.birthday_month + "-" + input.form1.applicant.birthday_day;
  const applicantAge = getAge(applicantBirthDay);
  const representative: reserveRepresentative = {
    age: applicantAge.toString(),
    birthdate: applicantBirthDay,
    gender: input.form1.applicant.gender,
    mileageNumber: input.form1.applicant.mileage_number,
    firstName: input.form1.applicant.name.kanji.first,
    lastName: input.form1.applicant.name.kanji.last,
    firstNameKana: input.form1.applicant.name.kana.first,
    lastNameKana: input.form1.applicant.name.kana.last,
    nameKana: input.form1.applicant.name.kana.last + " " + input.form1.applicant.name.kana.first,
    tel: input.form1.applicant.tel,
  };

  const people = getTotalTravelerNums(input.planSelectFormData);

  // 申込者が代表者じゃない場合
  if (input.form1.representative.is_applicant_join === 'no') {
    representative.age = getAge(input.form1.representative.birthday_year + '-' + input.form1.representative.birthday_month + '-' + input.form1.representative.birthday_day).toString();
    representative.birthdate = input.form1.representative.birthday_year + '-' + input.form1.representative.birthday_month + '-' + input.form1.representative.birthday_day;
    representative.gender = input.form1.representative.gender;
    representative.mileageNumber = input.form1.representative.mileage_number;
    representative.firstName = input.form1.representative.name.kanji.first;
    representative.lastName = input.form1.representative.name.kanji.last;
    representative.firstNameKana = input.form1.representative.name.kana.first;
    representative.lastNameKana = input.form1.representative.name.kana.last;
    representative.nameKana = input.form1.representative.name.kana.last + " " + input.form1.representative.name.kana.first;
    representative.tel = input.form1.representative.tel;
  }

  /**
   * 同行者
   */
  const childDetail = getChildDetail(input.planSelectFormData.searchParams);

  const travelCompanions: reserveTravelCompanions[] = [];
  if (input.form1.followers.length > 0) {
    input.form1.followers.forEach((followers) => {
      const followersBirthDay = followers.birthday_year + '-' + followers.birthday_month + '-' + followers.birthday_day;
      const followersAge = getAge(followersBirthDay);
      const setData:reserveTravelCompanions = {
        "birthdate": followersBirthDay,
        "age": followersAge,
        "gender": followers.gender,
        "mileageNumber": followers.mileage_number,
        "nameKana": followers.name.kana.last + followers.name.kana.first,
        "firstNameKana": followers.name.kana.first,
        "lastNameKana": followers.name.kana.last,
        "futon": true,
        "meal": true
      };

      if (followersAge <= 5) {
        const selectChildIndex = childDetail.findIndex((child) => {
          return Number(child.age) === Number(followersAge) && !child.check;
        })
        if (selectChildIndex >= 0) {
          childDetail[selectChildIndex].check = true;
          setData.meal = childDetail[selectChildIndex].meal;
          setData.futon = childDetail[selectChildIndex].futon;
        }
      }

      travelCompanions.push(setData);
    })
  }

  /**
   * フライト
   */
  const reserveConvertFlightData = convertReserveFlightData(input.planSelectFormData.flight);
  const departureDate = reserveConvertFlightData[0]?.inbound[0]?.useDate ?? '';
  const flight:reserveFlight[] = [];
  const flightReservationPassengers:flightReservationPassengers[] = [{
    "kana_last": representative.lastNameKana,
    "kana_first": representative.firstNameKana,
    "age": geAgeAtDeparture(representative.birthdate, departureDate).toString(),
    "m_gender_id": getFlightGenderId(representative.gender),
    "inf_last_name": "",
    "inf_first_name": "",
    "inf_age": "0",
    "inf_gender_id": "",
    "flag_representative": "1",
    "birthday": getFlightBirthday(representative.birthdate),
    "tel": representative.tel,
    "postal_code": input.form1.applicant.address.zip_code,
    "adress": input.form1.applicant.address.prefecture + input.form1.applicant.address.address_main + input.form1.applicant.address.address_sub,
    "mail_adress": input.form1.applicant.email,
  }];

  const infData: any[] = [];
  if (input.form1.followers.length > 0) {
    input.form1.followers.forEach((followers) => {
      const age = geAgeAtDeparture(followers.birthday_year+'-'+followers.birthday_month+'-'+followers.birthday_day, departureDate);
      if (age >= 3) {
        flightReservationPassengers.push({
          "kana_last": followers.name.kana.last,
          "kana_first": followers.name.kana.first,
          "age": age.toString(),
          "m_gender_id": getFlightGenderId(followers.gender),
          "flag_representative": "0",
        });
      } else {
        infData.push(followers);
      }
    });
    if (infData.length > 0) {
      infData.forEach((data, infIndex) => {
        if (flightReservationPassengers[infIndex]) {
          const age = geAgeAtDeparture(data.birthday_year+'-'+data.birthday_month+'-'+data.birthday_day, departureDate);
          flightReservationPassengers[infIndex].inf_age = age.toString();
          flightReservationPassengers[infIndex].inf_gender_id = getFlightGenderId(data.gender);
          flightReservationPassengers[infIndex].inf_last_name = data.name.kana.last;
          flightReservationPassengers[infIndex].inf_first_name = data.name.kana.first;
        }
      })
    }
  }

  if (reserveConvertFlightData.length > 0) {
    const flightPeople = getFlightPeopleNums(input.planSelectFormData.searchParams.roomDetail);
    const reserveFlights:reserveFlightData[] = [];
    let sort = 0;
    reserveConvertFlightData.forEach((flightDetail, fIndex) => {
      const flightData:reserveFlight = {
        "airline": flightDetail.inbound[0].airline ?? "",
        "departure_date": flightDetail.inbound[0].useDate ?? '',
        "return_date": flightDetail.outbound[0].useDate ?? '',
        "adults": flightPeople.count_adults.toString(),
        "children": flightPeople.count_children.toString(),
        "infants": flightPeople.count_baby.toString(),
        "newiit": "",
        "reservationPassengers": flightReservationPassengers,
        "reserveFlight": [],
        "order": fIndex + 1
      }
      if (flightDetail.inbound[0].airline === 'ANA') {
        flightData.newiit = "1";
      } else if (flightDetail.inbound[0].airline === 'JAL') {
        flightData.newiit = "4";
      }

      // 往路
      flightDetail.inbound.forEach((inboundAir) => {
        inboundAir.entity.forEach((entity) => {
          reserveFlights.push(setReserveFlightData(entity, sort, 1,"inbound"));
          sort++;
          getConnectionFlights(entity.FReservationConnectionFlight, []).forEach((connectionFlight) => {
            reserveFlights.push(setReserveFlightData(connectionFlight, sort, 1,"inbound"));
            sort++;
          })
        })
      })

      // 復路
      flightDetail.outbound.forEach((outboundAir) => {
        outboundAir.entity.forEach((outboundEntity) => {
          reserveFlights.push(setReserveFlightData(outboundEntity, sort, 2, "outbound"));
          sort++;
          getConnectionFlights(outboundEntity.FReservationConnectionFlight, []).forEach((connectionFlight) => {
            reserveFlights.push(setReserveFlightData(connectionFlight, sort, 2, "outbound"));
            sort++;
          })
        })
      })

      flightData.reserveFlight = reserveFlights;

      flight.push(flightData);
    })
  }

  /**
   * ホテル
   */
  const hotels: reserveHotel[] = [];
  input.planSelectFormData.hotel.forEach((hotelData, hIndex) => {
    const hotel:reserveHotel = {
      "hotelName": hotelData.detail.hotel_name,
      "roomName": hotelData.roomData?.room_name?? "",
      "planName": hotelData.plan?.room_plan_detail.plan_name ?? "",
      "hotel_code": hotelData.detail.hotel_code,
      "room_plan_code": hotelData?.planRoomCode ?? "",
      "check_in": hotelData.checkIn,
      "check_out": hotelData.checkOut,
      "check_in_time": hotelData.checkTime?? "",
      "check_out_in_time": "",
      "count_rooms": input.planSelectFormData.searchParams.rooms.toString(),
      "reservation_name1": input.form1.applicant.name.kanji.last,
      "reservation_name2": input.form1.applicant.name.kanji.first,
      "reservation_kana1": input.form1.applicant.name.kana.last,
      "reservation_kana2": input.form1.applicant.name.kana.first,
      "reservation_gender": getHotelGenderCode(input.form1.applicant.gender),
      "reservation_tel": input.form1.applicant.tel,
      "zip_code": input.form1.applicant.address.zip_code,
      "address1": input.form1.applicant.address.prefecture + input.form1.applicant.address.address_main,
      "address2": input.form1.applicant.address.address_sub,
      "address3": "",
      "memo": "",
      "stay_user_type": "1",
      "stay_name1": "",
      "stay_name2": "",
      "stay_kana1": "",
      "stay_kana2": "",
      "stay_gender": "",
      "stay_tel": "",
      "meal_breakfast": hotelData.plan?.room_plan_detail.meal_breakfast?? "0",
      "meal_dinner": hotelData.plan?.room_plan_detail.meal_dinner?? "0",
      "meal_lunch": hotelData.plan?.room_plan_detail.meal_lunch?? "0",
      "reservation_email": input.form1.applicant.email,
      "total_price": hotelData.plan?.room_plan_detail.total_price ?? 0,
      "stay_detail": hotelData.plan?.room_plan_detail.stay_detail_list ?? [],
      "order": hIndex + 1
    }

    // 予約者と旅行者が違う場合
    if (input.form1.representative.is_applicant_join === 'no') {
      hotel.stay_user_type = "2";
      hotel.stay_name1 = input.form1.representative.name.kanji.last;
      hotel.stay_name2 = input.form1.representative.name.kanji.first;
      hotel.stay_kana1 = input.form1.representative.name.kana.last;
      hotel.stay_kana2 = input.form1.representative.name.kana.first;
      hotel.stay_gender = getHotelGenderCode(input.form1.representative.gender);
      hotel.stay_tel = input.form1.representative.tel;
    }

    hotels.push(hotel);
  })

  /**
   * 基本プラン
   */
  const plans:reserveActivity[] = [];
  const selectPlans = input.planSelectFormData.activity.filter((activity) => {
    return activity.active;
  })
  selectPlans.forEach((planData,pIndex) => {
    const totalPrice = getActivityPlanPrice(planData.price_list);
    const plan:reserveActivity = {
      id: "plan_" + planData.plan_info.plan_code + "_" + pIndex,
      "status": "",
      "detail": planData.plan_info.description,
      "planName": planData.plan_info.plan_name,
      "plan_code": planData.plan_info.plan_code,
      "use_date": planData.date,
      "use_time": planData.time,
      "time_code": planData.time_code,
      "reservation_name1": input.form2.applicant2.name.kana.last,
      "reservation_name2": input.form2.applicant2.name.kana.first,
      "reservation_pref_id": getPlanAreaCode(input.form2.applicant2.prefecture).toString(),
      "reservation_tel": input.form2.applicant2.tel,
      "reservation_email": input.form2.applicant2.email,
      "reservation_gender": getActivityGenderCode(input.form2.applicant2.gender),
      "total_price": totalPrice.toString(),
      "price_type_detail": setPlanReservePriceTypeDetail(planData),
      "add_field_list": setPlanReserveAddFieldList(planData, input.form2.plans),
      "order": pIndex + 1,
      "planData": planData
    };

    plans.push(plan);
  })

  /**
   * 追加オプション
   */
  const options:reserveActivity[] = [];
  const selectOptions = input.planSelectFormData.reserveOptions.filter((activity) => {
    return activity.active;
  })
  selectOptions.forEach((planData, oIndex) => {
    const totalPrice = getActivityPlanPrice(planData.price_list);
    const option:reserveActivity = {
      id: "option_" + planData.plan_info.plan_code + "_" + oIndex,
      "status": "",
      "detail": planData.plan_info.description,
      "planName": planData.plan_info.plan_name,
      "plan_code": planData.plan_info.plan_code,
      "use_date": planData.date,
      "use_time": planData.time,
      "time_code": planData.time_code,
      "reservation_name1": input.form2.applicant2.name.kana.last,
      "reservation_name2": input.form2.applicant2.name.kana.first,
      "reservation_pref_id": getPlanAreaCode(input.form2.applicant2.prefecture).toString(),
      "reservation_tel": input.form2.applicant2.tel,
      "reservation_email": input.form2.applicant2.email,
      "reservation_gender": getActivityGenderCode(input.form2.applicant2.gender),
      "total_price": totalPrice.toString(),
      "price_type_detail": setPlanReservePriceTypeDetail(planData),
      "add_field_list": setOptionsReserveAddFieldList(planData, input.form2.options, oIndex),
      "order": oIndex + 1,
      "planData": planData
    };

    options.push(option);
  })

  /**
   * レンタカー
   */
  const reserveCarRental:reserveRentalCar[] = []
  const inboundAir = reserveConvertFlightData[0].inbound[0];
  const outboundAir = reserveConvertFlightData[0].outbound.slice(-1)[0];
  if (input.planSelectFormData.carRental.length > 0) {
    const rentalCarRentalPeople = getCarRentalReservePeople(input.planSelectFormData.searchParams);
    let carRentalOrder = 1;
    input.planSelectFormData.carRental.forEach((carRental, cIndex) => {
      carRental.detail.forEach((carRentalDetail, dIndex) => {
        let totalPrice = 0;
        const carRentalField:reserveRentalCar = {
          id: "carRental_" + carRental.departure_date + "_" + cIndex + "_" + dIndex,
          "reservation_name1": input.form1.applicant.name.kana.last,
          "reservation_name2": input.form1.applicant.name.kana.first,
          "reservation_tel": input.form1.applicant.tel,
          "reservation_email": input.form1.applicant.email,
          "departure_date": carRental.departure_date,
          "departure_time": carRental.departure_time,
          "return_date": carRental.return_date,
          "return_time": carRental.return_time,
          departure_date_label: carRental.departure_date_label,
          departure_office_label: carRental.departure_office_label,
          return_date_label: carRental.return_date_label,
          return_office_label: carRental.return_office_label,
          car_detail: [carRentalDetail],
          count_option: {
            baby_seat: carRentalDetail.count_option.baby_seat.num,
            child_seat: carRentalDetail.count_option.child_seat.num,
            junior_seat: carRentalDetail.count_option.junior_seat.num,
          },
          "total_price": totalPrice.toString(),
          "detail": [],
          "order": carRentalOrder,
        }
        const arrivalAirCode = getLastFlightAirCode(outboundAir.entity[0].FReservationConnectionFlight, outboundAir.entity[0].air_code);
        const arrivalAirSubNo = getLastFlightAirSubNo(outboundAir.entity[0].FReservationConnectionFlight, outboundAir.entity[0].air_code);
        const arrival_air_code = convertAirCodeForCarRental(arrivalAirCode, outboundAir.airline, arrivalAirSubNo);
        const departure_air_code = convertAirCodeForCarRental(inboundAir.entity[0].air_code, inboundAir.airline, inboundAir.entity[0].air_sub_no);

        let total = 0;
        const carRentalData:reserveCarRentalDetail = {
          "grade": carRentalDetail.planDetail?.class_name ?? "",
          "car_model": carRentalDetail.planDetail?.class_model ?? "",
          "class": carRentalDetail.planDetail?.class_name ?? "",
          "classModel": carRentalDetail.planDetail?.class_model ?? "",
          "plan_code": carRentalDetail.plan_code,
          "departure_office_code": carRental.departure_office_code,
          "return_office_code": carRental.return_office_code,
          "departure_date": carRental.departure_date,
          "departure_time": carRental.departure_time,
          "return_date": carRental.return_date,
          "return_time": carRental.return_time,
          "access_type": getCarRentalOfficeAccessType(carRental.departure_office_code),
          flight_arrival_no: getLastFlightAirSubNo(inboundAir.entity[0].FReservationConnectionFlight, inboundAir.entity[0].air_sub_no),
          flight_arrival_code: departure_air_code,
          flight_departure_no: outboundAir.entity[0].air_sub_no,
          flight_departure_code: arrival_air_code,
          "joshaadt": rentalCarRentalPeople.joshaadt.toString(),
          "joshainf": rentalCarRentalPeople.joshainf.toString(),
          "basic_price": carRentalDetail.planDetail?.basic_price.toString() ?? "",
          "indemnity_price": carRentalDetail.planDetail?.indemnity_price?.toString() ?? "",
          "anshin_price": "",
          "anshinpremium_price": "",
          "total_price": 0,
          "option_list": []
        };
        total += carRentalDetail.planDetail?.total_price ?? 0;

        // 安心パック設定
        if (carRentalDetail.count_option.anshin.used) {
          const anshinName = carRentalDetail.count_option.anshin.option_name
          const anshinData = carRentalDetail.planDetail?.anshin_pack_list.find((option) => option.anshin_name === anshinName);
          if (anshinData) {
            const anshinPrice = anshinData.anshin_price;
            total += anshinPrice;
            carRentalData.anshin_price = anshinPrice.toString()
          }
        }

        if (carRentalDetail.count_option.anshin_premium.used) {
          const anshinName = carRentalDetail.count_option.anshin_premium.option_name
          const anshinData = carRentalDetail.planDetail?.anshin_pack_list.find((option) => option.anshin_name === anshinName);
          if (anshinData) {
            const anshinPrice = anshinData.anshin_price;
            total += anshinPrice;
            carRentalData.anshinpremium_price = anshinPrice.toString()
          }
        }

        // オプション設定
        if (carRentalDetail.count_option.baby_seat.num > 0) {
          const optionCode = carRentalDetail.count_option.baby_seat.option_code;
          const babySeatData = carRentalDetail.planDetail?.option_list.find((option) => option.option_code === optionCode);
          if (babySeatData) {
            const num = carRentalDetail.count_option.baby_seat.num;
            const babySeatPrice = babySeatData.option_vaule_list.find((option) => option.num === num)?.price ?? 0;
            total += babySeatPrice;
            carRentalData.option_list.push({
              "option_code": babySeatData.option_code,
              "option_name": babySeatData.option_name,
              "num": num.toString(),
              "price": babySeatPrice.toString(),
            });
          }
        }

        if (carRentalDetail.count_option.child_seat.num > 0) {
          const optionCode = carRentalDetail.count_option.child_seat.option_code;
          const childSeatData = carRentalDetail.planDetail?.option_list.find((option) => option.option_code === optionCode);
          if (childSeatData) {
            const num = carRentalDetail.count_option.child_seat.num;
            const babySeatPrice = childSeatData.option_vaule_list.find((option) => option.num === num)?.price ?? 0;
            total += babySeatPrice;
            carRentalData.option_list.push({
              "option_code": childSeatData.option_code,
              "option_name": childSeatData.option_name,
              "num": num.toString(),
              "price": babySeatPrice.toString(),
            });
          }
        }

        if (carRentalDetail.count_option.junior_seat.num > 0) {
          const optionCode = carRentalDetail.count_option.junior_seat.option_code;
          const juniorSeatData = carRentalDetail.planDetail?.option_list.find((option) => option.option_code === optionCode);
          if (juniorSeatData) {
            const num = carRentalDetail.count_option.junior_seat.num;
            const babySeatPrice = juniorSeatData.option_vaule_list.find((option) => option.num === num)?.price ?? 0;
            total += babySeatPrice;
            carRentalData.option_list.push({
              "option_code": juniorSeatData.option_code,
              "option_name": juniorSeatData.option_name,
              "num": num.toString(),
              "price": babySeatPrice.toString(),
            });
          }
        }

        carRentalData.total_price = total;
        totalPrice += total;
        carRentalField.detail.push(carRentalData);
        carRentalField.total_price = totalPrice.toString();
        reserveCarRental.push(carRentalField);
        carRentalOrder++;
      })
    })
  }
  /**
   * 保険
   */
  const toDate = dayjs().format('YYYY-MM-DD');
  const insurance:reserveInsurance = {
    insurance: false,
    cancelInsurance: false,
    serviceInsurance: {
      enable: false,
      tripStartDate: "",
      tripEndDate: "",
      people: 0
    }
  }

  const insuranceData: reserveInsuranceData = {
    isDTA: false,
    isTripCXL: false,
    DTA: {
      tripStartDate: "",
      tripEndDate: ""
    },
    TripCXL: {
      tripStartDate: "",
      tripEndDate: ""
    },
    applicantBirth: '',
    travelCompanions:[],
    cancelTravelCompanions: [],
  };

  if (input.form3.service_insurance.enable) {
    insurance.serviceInsurance.enable = true;
    insurance.serviceInsurance.tripStartDate = input.form3.service_insurance.subscription_period.start;
    insurance.serviceInsurance.tripEndDate = input.form3.service_insurance.subscription_period.end;
    insurance.serviceInsurance.people = input.form3.service_insurance.people;
  }
  
  if (input.form3.disability_insurance.agree === 'yes' && input.form3.disability_insurance.we_do_join === "yes") {
    insurance.insurance = true;
    insuranceData.isDTA = true;
    insuranceData.DTA.tripStartDate = input.form3.disability_insurance.subscription_period.start;
    insuranceData.DTA.tripEndDate = input.form3.disability_insurance.subscription_period.end;
  }
  insurance.period = {
    start: input.form3.disability_insurance.subscription_period.start,
    end: input.form3.disability_insurance.subscription_period.end,
  }

  if (input.form3.cancellation_insurance.agree === 'yes' && input.form3.cancellation_insurance.we_do_join === "yes") {
    insurance.cancelInsurance = true;
    insuranceData.isTripCXL = true;
    insuranceData.TripCXL.tripStartDate = toDate;
    insuranceData.TripCXL.tripEndDate = input.planSelectFormData.searchParams.start;
  }
  
  insuranceData.applicantBirth = input.form1.applicant.birthday_year + '-' + input.form1.applicant.birthday_month + '-' + input.form1.applicant.birthday_day;
  if (input.form1.representative.is_applicant_join === 'yes') {
    const year = input.form1.applicant.birthday_year;
    const month = input.form1.applicant.birthday_month;
    const day = input.form1.applicant.birthday_day;
    // 80歳以下であればパラメーターを追加する
    if (getAgeFromBirthday(year, month, day, insuranceData.DTA.tripStartDate) <= 80) {
      insuranceData.travelCompanions.push({
        dateOfBirth: input.form1.applicant.birthday_year + '-' + input.form1.applicant.birthday_month + '-' + input.form1.applicant.birthday_day
      });
    }
    insuranceData.cancelTravelCompanions.push({
      dateOfBirth: input.form1.applicant.birthday_year + '-' + input.form1.applicant.birthday_month + '-' + input.form1.applicant.birthday_day
    });
  } else {
    const year = input.form1.representative.birthday_year;
    const month = input.form1.representative.birthday_month;
    const day = input.form1.representative.birthday_day;
    // 80歳以下であればパラメーターを追加する
    if (getAgeFromBirthday(year, month, day, insuranceData.DTA.tripStartDate) <= 80) {
      insuranceData.travelCompanions.push({
        dateOfBirth: input.form1.representative.birthday_year + '-' + input.form1.representative.birthday_month + '-' + input.form1.representative.birthday_day
      });
    }
    insuranceData.cancelTravelCompanions.push({
      dateOfBirth: input.form1.representative.birthday_year + '-' + input.form1.representative.birthday_month + '-' + input.form1.representative.birthday_day
    });
  }

  if (input.form1.followers.length > 0) {
    input.form1.followers.forEach((follower) => {
      const year = follower.birthday_year;
      const month = follower.birthday_month;
      const day = follower.birthday_day;
      // 80歳以下であればパラメーターを追加する
      if (getAgeFromBirthday(year, month, day, insuranceData.DTA.tripStartDate) <= 80) {
        insuranceData.travelCompanions.push({
          dateOfBirth: follower.birthday_year + '-' + follower.birthday_month + '-' + follower.birthday_day
        });
      }
    })
  }

  return {
    paymentId: input.form4.payment.token,
    seller: input.planSelectFormData.planData?.info.body.userId ?? "",
    reserve: {
      applicant: {
        address: {
          address1: input.form1.applicant.address.address_main,
          address2: input.form1.applicant.address.address_sub,
          prefecture: input.form1.applicant.address.prefecture,
          zipCode: input.form1.applicant.address.zip_code,
        },
        firstName: input.form1.applicant.name.kanji.first,
        lastName: input.form1.applicant.name.kanji.last,
        firstNameKana: input.form1.applicant.name.kana.first,
        lastNameKana: input.form1.applicant.name.kana.last,
        nameKana: input.form1.applicant.name.kana.last + " " + input.form1.applicant.name.kana.first,
        gender: input.form1.applicant.gender,
        birthdate: input.form1.applicant.birthday_year + '-' + input.form1.applicant.birthday_month + "-" + input.form1.applicant.birthday_day,
        email: input.form1.applicant.email,
        applicantName: input.form1.applicant.name.kanji.last+input.form1.applicant.name.kanji.first,
        emergencyTel: input.form1.applicant.emergency_tel,
        tel: input.form1.applicant.tel,
        mileageNumber: input.form1.applicant.mileage_number,
        isApplicantJoin: input.form1.representative.is_applicant_join,
      },
      departureDate: input.planSelectFormData.searchParams.start,
      returnDate: input.planSelectFormData.searchParams.end,
      insuranceTerm: "", // 保険
      representative: representative,
      travelCompanions: travelCompanions,
      reservedOverview: {
        people: people
      },
      needConsideration: input.need_consideration,
      tourist: input.tourist,
      etc: input.form1.note,
      insurance: insurance,
    },
    flight: flight,
    hotel: hotels,
    plan: plans,
    options: options,
    rentalCar: reserveCarRental,
    insurance: insuranceData,
    discounts: input.planSelectFormData.discounts,
    insuranceQuoteRequest: getInsurancePrams({
      form1: input.form1,
      planSelectFormData: input.planSelectFormData
    }),
    coupons: input.planSelectFormData.coupons,
    departureAirport: input.planSelectFormData.searchParams.to
  };
}

/**
 * 予約API
 * @param input
 */
export const reserveAPI = async (input: { form1: InputsForm1, form2: InputsForm2, form3: InputsForm3, form4: InputsForm4, planSelectFormData: PlanSelectFormData , need_consideration:string, tourist: string}) => {
  try {
    const reserveParams = getReserveRequestParams(input);
    const response = await useApi.post({
      url: "/reserve",
      data: reserveParams
    });

    const result = response.data as reserveResponce;

    return {status: result.status, response:result};

  } catch (error) {
    console.error("credit error", error);
  }

  return {status: 500, data: null}
};

export const getReserveGenderLabel = (gender: string) => {
  if (gender === 'male') {
    return '男性';
  }

  return '女性';
}

/**
 * キャンセル可能な追加オプションがあるか
 * @param reservation
 */
export const hasReservePartCancel = (reservation: reserveCancelDetailResponce) => {
  if (reservation.data.bookingData.status === "cancel") {
    return false;
  }

  if (reservation.cancelData.carRental.length > 0) {
    const isReservedData = reservation.cancelData.carRental.find((carRental) => {return carRental.data.status === 'reserved'})
    if (isReservedData) {
      return true;
    }
  }

  if (reservation.cancelData.options.length > 0) {
    const isReservedData = reservation.cancelData.options.find((option) => {return option.data.status === 'reserved'})
    if (isReservedData) {
      return true;
    }
  }

  return false;
}

export const isOption = (planSelectForm: PlanSelectFormData) => {
  const selectPlan = planSelectForm.activity.filter((activity) => {
    return activity.active;
  })
  
  const selectOption = planSelectForm.reserveOptions.filter((option) => {
    return option.active;
  })
  
  if (selectPlan.length > 0 || selectOption.length > 0) {
    return true;
  }
  
  return false;
}

export const getInsurancePrams = (input: {
  form1: InputsForm1,
  planSelectFormData: PlanSelectFormData
}) => {
  const insuranceParams: insuranceQuoteRequest = {
    isServiceInsurance: input.planSelectFormData.planData?.product?.serviceInsuranceSettings?.status === 'enabled' ?? true,
    amount: input.planSelectFormData.travelPrice,
    isDTA: false,
    isTripCXL: false,
    DTA: {
      tripStartDate: input.planSelectFormData.searchParams.start,
      tripEndDate: input.planSelectFormData.searchParams.end
    },
    TripCXL: {
      tripStartDate: input.planSelectFormData.searchParams.start,
      tripEndDate: input.planSelectFormData.searchParams.start
    },
    applicantBirth: "",
    travelCompanions: [],
    cancelTravelCompanions: [],
    serviceInsurance: {
      tripStartDate: input.planSelectFormData.searchParams.start,
      arrivalAirport: input.planSelectFormData.searchParams.to
    }
  }
  const savedForm1 = input.form1;
  insuranceParams.applicantBirth = savedForm1.applicant.birthday_year + '-' + savedForm1.applicant.birthday_month + '-' + savedForm1.applicant.birthday_day;
  if (savedForm1.representative.is_applicant_join === 'yes') {
    const year = savedForm1.applicant.birthday_year;
    const month = savedForm1.applicant.birthday_month;
    const day = savedForm1.applicant.birthday_day;
    // 80歳以下であればパラメーターを追加する
    if (getAgeFromBirthday(year, month, day, insuranceParams.DTA.tripStartDate) <= 80) {
      insuranceParams.travelCompanions.push({
        dateOfBirth: savedForm1.applicant.birthday_year + '-' + savedForm1.applicant.birthday_month + '-' + savedForm1.applicant.birthday_day
      });
    }
    insuranceParams.cancelTravelCompanions.push({
      dateOfBirth: savedForm1.applicant.birthday_year + '-' + savedForm1.applicant.birthday_month + '-' + savedForm1.applicant.birthday_day
    });
  } else {
    const year = savedForm1.representative.birthday_year;
    const month = savedForm1.representative.birthday_month;
    const day = savedForm1.representative.birthday_day;
    // 80歳以下であればパラメーターを追加する
    if (getAgeFromBirthday(year, month, day, insuranceParams.DTA.tripStartDate) <= 80) {
      insuranceParams.travelCompanions.push({
        dateOfBirth: savedForm1.representative.birthday_year + '-' + savedForm1.representative.birthday_month + '-' + savedForm1.representative.birthday_day
      });
    }
    insuranceParams.cancelTravelCompanions.push({
      dateOfBirth: savedForm1.representative.birthday_year + '-' + savedForm1.representative.birthday_month + '-' + savedForm1.representative.birthday_day
    });
  }

  if (savedForm1.followers.length > 0) {
    savedForm1.followers.forEach((followers) => {
      const year = followers.birthday_year;
      const month = followers.birthday_month;
      const day = followers.birthday_day;
      // 80歳以下であればパラメーターを追加する
      if (getAgeFromBirthday(year, month, day, insuranceParams.DTA.tripStartDate) <= 80) {
        insuranceParams.travelCompanions.push({
          dateOfBirth: followers.birthday_year + '-' + followers.birthday_month + '-' + followers.birthday_day
        });
      }
    })
  }
  if(insuranceParams.travelCompanions.length > 0) {
    insuranceParams.isDTA = true;
  }
  if (insuranceParams.cancelTravelCompanions.length > 0) {
    insuranceParams.isTripCXL = true;
  }

  return insuranceParams;
}