import React from "react";

// smooth-scroll
import { animateScroll as scroll } from 'react-scroll';

import { useLocation, useParams } from "react-router-dom";
import {PlanSelectFormData} from "../../types/planSelectForm";
import {getSellerId} from "../../utils/footer";
import draftToHtml from "draftjs-to-html";
import DOMPurify from "dompurify";


type Props = {
  sellerId: number | undefined
  supportDial: string | undefined,
  businessHours: string | undefined
}

const Footer: React.VFC<Props> = ({
  sellerId,
  supportDial,
  businessHours
}) => {

  const simpleNavi = useLocation().pathname;

  // go to top of page
  const scrollToTop = () => {
    scroll.scrollToTop(); 
  };

  const createData = (
    title: string,
    href: string,
    target: string,
  ) => {
    return { title, href, target };
  };
  
  // 初期化
  const rows = [
    // TODO: URL情報をディレクターからもらう
    createData("旅行業約款", "/Agreement",""),
    createData("旅行条件書", "/Conditions",""),
    createData("プライバシーポリシー", "https://otspremium.com/aboutus/privacypolicy/","_blank"),
    createData("キャンセルポリシー", "/Conditions/#sec_14",""),
    createData("会社案内", "https://otspremium.com/aboutus/outline/","_blank"),
    createData("よくあるご質問", "/faq",""),
  ];

  const createMarkup = (content: string) => {
    if(content) {
      return  {
        __html: DOMPurify.sanitize(content, { ADD_ATTR: ['target'] })
      }
    }
  }
  
  
  return (
    <footer className="l-site-footer">
        <>
          {simpleNavi == "/page404" || simpleNavi == "/Conditions" ? (<></>):(
            <>

              {supportDial && (
                  <>
                    <div className="l-site-footer-contact">
                      <div className="wrap-content-900 p-r">
                        <p className="l-site-footer-contact-heading">お問い合わせ</p>
                        <p className="l-site-footer-contact-company">
                          デジタルDMOカスタマーサポート
                        </p>
                        <p className="l-site-footer-contact-call">
                          <a
                              href={"tel:"+supportDial}
                              className="l-site-footer-contact-call-link"
                          >
                            <i className="icon-call-blue mr-5 fz-18"></i>{supportDial}
                          </a>
                        </p>
                        <dl className="l-site-footer-contact-inqury d-f mb-5">
                          <dt>営業時間：</dt>
                          <dd dangerouslySetInnerHTML={createMarkup(businessHours?.replace(/\n/g, '<br>') ?? "")}>
                          </dd>
                        </dl>
                        {simpleNavi !== "/login" && sellerId && sellerId > 0 && (
                          <p className="l-site-footer-contact-inqury">
                            お問い合わせコード：{getSellerId((sellerId.toString().padStart(6, '0')))}
                          </p>
                        )}

                        <div className="page-to-top">
                        <span onClick={scrollToTop} className="page-to-top-link">
                          <p className="page-to-top-link-icon"><i className="icon-top-arrow-cyan_blue fz-24"></i></p>
                          <p className="page-to-top-link-text">ページトップ</p>
                        </span>
                        </div>
                      </div>
                    </div>
                  </>
              )}
            </>
          )}
        </>

        <div className="l-site-footer-navi">
          <div className="wrap-content-900">
            <div className="l-site-footer-navi-content">
              <div className="l-site-footer-navi-content-menu">
                <ul className="l-site-footer-navi-content-menu-list">
                  {rows.map((row, index) => (
                    <li key={index} className="l-site-footer-navi-content-menu-list-item">
                      <a
                        href={row.href}
                        className="l-site-footer-navi-content-menu-list-item-link"
                        target={row.target}
                      >
                        {row.title}
                      </a>
                  </li>
                  ))}
                </ul>
              </div>

              {/* コピーライト */}
              <div className="l-site-footer-navi-content-copyright">
                Copyright © OKINAWA TOURIST SERVICE All Rights Reserved.
              </div>
              
            </div>
          </div>
        </div>
      </footer>
  );
};

export default Footer;