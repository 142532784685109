/**
 * 標準のフッター情報を表示するページ
 */
export const defaultFooterPages = [
    '/login'
]

export const defaultFooter = {
    sellerId: 0,
    supportDial: '098-917-2593',
    businessHours: '平日10：00～18：00\n土曜10：00～14：00　日祝休み'
}

export const siteTitle = 'デジタルDMOプラットフォーム';