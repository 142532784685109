import React, {useContext, useEffect, useState} from "react";

import { RootState } from "../redux/store";
import { useSelector, useDispatch } from "react-redux";
import { save } from "../redux/form3Slice";
import { SubmitHandler, useForm } from "react-hook-form";
import {useLocation, useNavigate} from "react-router-dom";
import { InputsForm3 } from "../types/InputsForm3";
import { getInsurancePrams } from "../utils/reserve"

//MainTemplate
import MainTemplate from "./MainTemplate";

//scss
import "../scss/common.scss";
import "../scss/component.scss";
import "../scss/pages/form3.scss";

//parts import
import FormStep from "./Form/FormStep";
import {
  SectionDisabilityInsurance,
  SectionCancellationInsurance
} from "./section/Form3"
import {PlanSelectFormData} from "../types/planSelectForm";
import {Backdrop, CircularProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useInsurancePrice} from "../utils/useInsurancePrice";
import {isOption} from "../utils/reserve";
import {devLog} from "../utils/errors";
import {getHeadTitle} from "../utils/title";
import {FormSessionContext} from "../hooks/FormSession";
import {TagManagerContext, TagManagerType} from "../hooks/GTM";
import draftToHtml from "draftjs-to-html";
import DOMPurify from "dompurify";
import {markupContentExist} from "../utils/freeSpace";
import { PartsTutorialButton } from "./parts";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Form3: React.FC = () => {
  const tagManagerContext: TagManagerType = useContext(TagManagerContext);
  const {checkSession} = useContext(FormSessionContext);
  const classesForLoading = useStyles();
  const location = useLocation()
  const [planSelectFormData, setPlanSelectFormData] = useState<{ data: PlanSelectFormData }>(location.state as { data: PlanSelectFormData }) //TODO: need change
  // reduxに保存されているデータをデバッグ
  // for debug
  const savedState = useSelector((state: RootState) => state);
  devLog("redux form1〜2", {
    ...savedState.form1,
    ...savedState.form2,
  });
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const navigate = useNavigate();

  const storedForm3:InputsForm3 = useSelector((state: RootState) => state.form3.form3);

  const dispatch = useDispatch();


  const setDefaultDate = ():any => {
    if (storedForm3.disability_insurance.subscription_period.start === '' && storedForm3.disability_insurance.subscription_period.end === '') {
      return {...storedForm3, ...{
          disability_insurance: {
            subscription_period: {
              start: planSelectFormData.data.searchParams.start,
              end: planSelectFormData.data.searchParams.end,
            }
          }
        }
      }
    }
    return {...storedForm3};
  }
  const form3 = setDefaultDate();

  const {
    control,
    formState: { isSubmitting, errors },
    getValues,
    setValue,
    handleSubmit,
    register,
  } = useForm<InputsForm3>({
    defaultValues: setDefaultDate(),
  });

  const onSubmit: SubmitHandler<any> = (data) => {
    const session = checkSession(planSelectFormData.data);
    if (session) {
      const params = getInsurancePrams({
        form1: savedState.form1.form1,
        planSelectFormData: planSelectFormData.data
      })

      if (insuranceData.status === 200 && insuranceData.price.ServiceInsurance.isServiceInsurance) {
        data.service_insurance.enable = true;
        data.service_insurance.subscription_period.start = planSelectFormData.data.searchParams.start;
        data.service_insurance.subscription_period.end = planSelectFormData.data.searchParams.end;
        data.service_insurance.people = params.travelCompanions.length
      }
      dispatch(save({ form3: data }));
      navigate("/Form4",{ state: {
          data: planSelectFormData.data
        } });
    }
  };

  const [insuranceFee, setInsuranceFee] = React.useState(0);

  
  const [ fetchInsurance, insuranceData, { isLoading, isError, error } ] = useInsurancePrice();
  useEffect(() => {
    // todo 初期加入期間設定
    const params = getInsurancePrams({
      form1: savedState.form1.form1,
      planSelectFormData: planSelectFormData.data
    })
    
    if (form3.disability_insurance.subscription_period.start !== '' && form3.disability_insurance.subscription_period.end !== '') {
      params.DTA.tripStartDate = form3.disability_insurance.subscription_period.start;
      params.DTA.tripEndDate = form3.disability_insurance.subscription_period.end;
    }
    
    fetchInsurance(params);
    tagManagerContext.pageView(getHeadTitle(planSelectFormData.data, "保険加入の選択"))
  }, []);
  
  const updateInsurance = (start: string, end: string) => {
    const params = getInsurancePrams({
      form1: savedState.form1.form1,
      planSelectFormData: planSelectFormData.data
    });
    params.DTA.tripStartDate = start;
    params.DTA.tripEndDate = end;
    fetchInsurance(params);
  }
  
  useEffect(() => {
    if (isError) {
      // todo 保険の処理を修正するまでコメントアウト
      // alert(error);
      // window.location.href = getBackSearchURL(planSelectFormData.data.searchParams, planSelectFormData.data.id);
    }
  }, [isError])
  
  let buttonIconLabel = "4";
  let backButtonLabel = "オプション情報入力に戻る";
  if (!isOption(planSelectFormData.data)) {
    buttonIconLabel = "3";
    backButtonLabel = "お客様情報入力に戻る";
  }
  
  const isFreeSpace = () => {
    if (insuranceData.price.ServiceInsurance.isServiceInsurance && insuranceData.price.ServiceInsurance.freeSpace.open === 'enable') {
      return true
    }
    return false
  }
  const createMarkup = (content: string) => {
    if(content) {
      const html = draftToHtml(JSON.parse(content));
      return  {
        __html: DOMPurify.sanitize(html, { ADD_ATTR: ['target'] })
      }
    }
  }

  return (
    <MainTemplate title={getHeadTitle(planSelectFormData.data, "保険加入の選択")} planSelectFormData={planSelectFormData.data}>
      {/* ローディングの表示(APIのレスポンス待ち) */}
      <Backdrop className={classesForLoading.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

        <div className="l-page-content-upper_navi">
          <div className="wrap-content-900">
            <h1 className="l-page-content-upper_navi-heading">
              保険加入の選択
            </h1>
          </div>
        </div>

        <div className="l-page-content">
          <div className="wrap-content-900">
            <FormStep activeStep={3} isOption={isOption(planSelectFormData.data)}></FormStep>

            {/* 操作ガイドボタン */}
            <PartsTutorialButton value="component-content" />

            <form action="" onSubmit={handleSubmit(onSubmit)}>
              {/* <!--アラート--> */}
              {(insuranceData.price.isTripCXL || !isFreeSpace()) && (
                <div className="box-border-blue small icon-alert">
                  <p className="fz-16 c-blue">保険をご検討ください（任意）</p>
                </div>
              )}

              {isFreeSpace() && (
                <>
                  {/* サービス保険 //PC */}
                  {(insuranceData.price.ServiceInsurance.freeSpace.pc && markupContentExist(insuranceData.price.ServiceInsurance.freeSpace.pc)) ?
                    <>
                      <div className="box-freespace-item">
                        <div className="d-n d-b-md">
                          <div className="box-infomation-white-small mt-16-md mb-16">
                            <p className="fz-16 fw-b c-navy mb-8"><i className="icon-infomation"></i>サービス保険について</p>
                            <div className="freespace-wrap">
                              <div className="freespace-wrap-item">
                                <p className="fz-14" dangerouslySetInnerHTML={createMarkup(insuranceData.price.ServiceInsurance.freeSpace.pc)}></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </> : ""
                  }

                  {/* サービス保険 //SP */}
                  {(insuranceData.price.ServiceInsurance.freeSpace.sp && markupContentExist(insuranceData.price.ServiceInsurance.freeSpace.sp)) ?
                    <>
                      <div className="box-freespace-item">
                        <div className="mt-16 d-n-md">
                          <div className="box-infomation-white-small mt-16-md mb-16">
                            <p className="fz-14 fw-b c-navy mb-8"><i className="icon-infomation"></i>サービス保険について</p>
                            <div className="freespace-wrap">
                              <div className="freespace-wrap-item">
                                <p className="fz-14" dangerouslySetInnerHTML={createMarkup(insuranceData.price.ServiceInsurance.freeSpace.sp)}></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </> : ""
                  }
                </>
              )}

              {(insuranceData.price.isDTA && insuranceData.price.DTA > 0 && !insuranceData.price.ServiceInsurance.isServiceInsurance) && (
                <>
                  {/* <!--国内旅行傷害保険--> */}
                  <SectionDisabilityInsurance
                    planSelectFormData={planSelectFormData.data}
                    insuranceData={insuranceData}
                    register={register}
                    getValues={getValues}
                    setValue={setValue}
                    form3={form3}
                    control={control}
                    errors={errors}
                    insuranceFee={insuranceFee}
                    updateInsurance={updateInsurance}
                  >
                  </SectionDisabilityInsurance>
                </>
              )}

              {/*旅のキャンセル保険*/}
              {insuranceData.price.isTripCXL && (
                <SectionCancellationInsurance
                  register={register}
                  insuranceData={insuranceData}
                  planSelectFormData={planSelectFormData.data}
                  getValues={getValues}
                  control={control}
                  errors={errors}
                  insuranceFee={insuranceFee}
                >
                </SectionCancellationInsurance>
              )}

              <div className="wrap-content-350-sm">
                <p className="mt-40 mt-50-md">引受保険会社：Chubb損害保険株式会社</p>
                <p>取扱代理店　：沖縄ツーリスト株式会社</p>
                <p>承認番号　　：L2210309</p>
              </div>

              {/*card*/}

              {/* <!--次へ進む--> */}
              <div className="mt-48 mt-64-mt">
                <ul className="form-step_button mt-24">
                  <li className="form-step_button-next">
                    <button
                      type="submit"
                      className="button-large-red"
                      // todo: class作る
                      style={{
                        display: "block",
                        border: "none",
                        width: "100%",
                      }}
                      // disabled={!isDirty || isSubmitting} // note: これだとhistory.back()で戻ったときにdisabledになる
                      disabled={isSubmitting}
                    >
                      <span className="label-number-orange mr-5">{buttonIconLabel}</span>
                      決済情報の入力へ進む
                    </button>
                  </li>
                  <li className="form-step_button-back">
                    <button
                      type="button"
                      className="button-small-border_gray_blue button-medium-md"
                      onClick={() => {
                        window.history.back();
                      }}
                    >
                      <i className="icon-left_arrow mr-5"></i>
                      {backButtonLabel}
                    </button>
                  </li>
                </ul>
              </div>
            </form>
          </div>
        </div>

    </MainTemplate>
  );
};

export default Form3;
